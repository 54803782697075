import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './query.scss';

import { Grid, Button, Divider } from '@mui/material';

const ButtonInTabs = ({ className, onClick, children }) => {
  return <Button className={className} onClick={onClick} children={children} />;
};
const Query = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div id="Query">
      {/* <div id="search-bar">
        <input type="text" placeholder="Search" />
      </div> */}
      <Box id="message-list">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Inbox" {...a11yProps(0)} sx={{ textTransform: 'none' }} />
            <Tab label="Outbox" {...a11yProps(1)} sx={{ textTransform: 'none' }} />
            <Tab label="Archive" {...a11yProps(2)} sx={{ textTransform: 'none' }} />
           
          </Tabs>
        </Box>
        <Button
        sx={{float:'right',marginTop:'-46px'}}
            //onClick={() => setShowThirdTab(true)}
            // className={classes.addButton}
            >
              + Add query
            </Button>
        <TabPanel value={value} index={0}>
        <Box sx={{border:'1px solid #EEE'}}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
            <div id="searchbar">
        <input type="text" placeholder="Search" />
      </div>
              <Box>
                {na.map((res, index) => (
                  <div className="conver" key={index}>
                    <div className="titletext">{res.name}</div>
                    <div className="createddate">{res.date}</div>
                    <div className="conversationmessage">{`Patient: ${res.patient}`}</div>
                    <div className="conversationmessage">{`Subject:${res.subject}`}</div>
                  </div>
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} sm={10}>
            
              {/* <img src="/static/images/qqq.PNG" alt="im" /> */}
              <Box sx={{ backgroundColor: 'white' }}>
                <p className="inde">CDI QUERY</p>
                <p className="subind">Adam Davidson</p>
              </Box>
              <Divider />
              <Box sx={{ backgroundColor: 'white' }}>
                <Button startIcon={<i className="fa fa-reply" />} sx={{ textTransform: 'none' }}>
                  Reply
                </Button>
                <Button
                  startIcon={<i className="fa fa-share" aria-hidden="true" />}
                  sx={{ textTransform: 'none' }}
                >
                  Forward
                </Button>
                <Button
                  startIcon={<i className="fa fa-archive" aria-hidden="true" />}
                  sx={{ textTransform: 'none' }}
                >
                  Archive
                </Button>
                <Button
                  startIcon={<i className="fa fa-trash" aria-hidden="true" />}
                  sx={{ textTransform: 'none' }}
                >
                  Delete
                </Button>
              </Box>
              <Divider />
              <p className="subind"></p>
              <Box sx={{ p: 3 }}>
                <p>Pt admitted with PNA, and on arival Cr is 1.7,, GFr-41.7</p>
                <p>Please clariry in chart if pt has:-</p>
                <p>1) CKD stage 2</p>
                <p>2) AKI on CKD Stage 2</p>
                <p>3) AKI</p>
                <p>4) CKD stage (please specify)</p>
                <p>4) Other</p>
                <p>5) Unable to Determine</p>
                <br />
                <p>Thanks,</p>
                <p>Adam Davidson</p>
                DCI
              </Box>
            </Grid>
          </Grid>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          Item Two
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </div>
  );
};
export default Query;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const na = [
  {
    name: 'Adam Davidson',
    date: '20/05/98',
    patient: 'Thomas, Cameron',
    subject: 'CDI QUERY',
  },
  {
    name: 'Adam Davidson',
    date: '20/05/98',
    patient: 'Thomas, Cameron',
    subject: 'CDI QUERY',
  },
  {
    name: 'Adam Davidson',
    date: '20/05/98',
    patient: 'Thomas, Cameron',
    subject: 'CDI QUERY',
  },
  {
    name: 'Adam Davidson',
    date: '20/05/98',
    patient: 'Thomas, Cameron',
    subject: 'CDI QUERY',
  },
  {
    name: 'Adam Davidson',
    date: '20/05/98',
    patient: 'Thomas, Cameron',
    subject: 'CDI QUERY',
  },
  {
    name: 'Adam Davidson',
    date: '20/05/98',
    patient: 'Thomas, Cameron',
    subject: 'CDI QUERY',
  },
    {
      name: 'Adam Davidson',
      date: '20/05/98',
      patient: 'Thomas, Cameron',
      subject: 'CDI QUERY',
    },
];
//communicationResponse
// {
//   "resourceType": "Communication",
//   "id": "COMMUNICATION_ID",
//   "text": {
//       "status": "generated",
//       "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Query response to a Request</div>"
//   },
//   "identifier": [
//       {
//           "system": "http://www.providerco.com/communication",
//           "value": "12345"
//       }
//   ],
//   "basedOn": [
//       {
//           "reference": "CommunicationRequest/ID"
//       }
//   ],
//   "status": "completed",
//   "category": [
//       {
//           "coding": [
//               {
//                   "system": "http://acme.org/messagetypes",
//                   "code": "SolicitedAttachment"
//               }
//           ]
//       }
//   ],
//   "subject": {
//       "reference": "Patient/cdex-example-patient"
//   },
//   "sent": "SENTDATE",
//   "recipient": [
//       {
//           "reference": "Organization/cdex-example-payer"
//       }
//   ],
//   "sender": {
//       "reference": "Organization/cdex-example-provider"
//   },
//   "payload": ["PAYLOAD"]
// }





//communicationRequest
// {
//   "resourceType": "CommunicationRequest",
//   "contained": [
//       {
//           "resourceType": "Organization",
//           "id": "ORGANIZATIONID",
//           "identifier": [
//               {
//                   "system": "SYSTEMURL",
//                   "value": "SYSTEMNAME"
//               }
//           ]
//       }],
//   "id": "RESOURCE_ID",
//   "meta": {
//       "versionId": "1",
//   },
//   "text": {
//       "status": "generated",
//       "div": "<div xmlns=\"http://www.w3.org/1999/xhtml\">Request for Condition resources</div>"
//   },
//   "identifier": [
//       {
//           "system": "http://www.jurisdiction.com/insurer/123456",
//           "value": "ABC123"
//       }
//   ],
//   "groupIdentifier": {
//   "value": "12345"
//   },
//   "status": "active",
//   "category": [
//   {
//       "coding": [
//               {
//                   "system": "http://acme.org/messagetypes",
//                   "code": "SolicitedAttachmentRequest"
//               }
//           ]
//       }
//   ],
//   "priority": "routine",
//   "medium": [
//   {
//           "coding": [
//               {
//                   "system": "http://terminology.hl7.org/CodeSystem/v3-ParticipationMode",
//                   "code": "WRITTEN",
//                   "display": "written"
//               }
//           ],
//           "text": "written"
//       }
//   ],
//   "subject": {
//       "reference": "Patient/cdex-example-patient"
//    },
//   "about": [
//       {
//           "reference": "Claim/cdex-example-claim"
//       }
//   ],
//   "encounter": {
//       "reference": "Encounter/cdex-example-encounter"
//   },
//     "payload": [

//     ],
//   "occurrenceDateTime": "2016-06-10T11:01:10-08:00",
//   "authoredOn": "TIMESTAMP",
//   "requester": {
//       "reference": "Practitioner/cdex-example-practitioner"
//   },
//   "sender": {
//       "reference": "Organization/cdex-example-organization"
//   },
//   "recipient": [{
//       "reference": "ORGANIZATIONID"
//   }]
// };