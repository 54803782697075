import { faEarlybirds } from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import GaugeChart from 'react-gauge-chart';
import { firebas as firebase } from '../MedicalNecessary/firebase';
import { usePatient } from '../PatientProvider';
// import FHIR from 'fhirclient';
const firestore = firebase.firestore();
const Guage = (props) => {
  const patient = usePatient();
  const [pat, setpat] = React.useState(patient);
  const [d, setd] = React.useState();
  React.useEffect(() => {
    firestore
      .collection(`um_case_reviews/`)
      .get()
      .then((data) => {
        // console.log(data);
        let pdata = [];
        data.forEach((doc) => {
          pdata.push(doc.data());
        });
        return setd(pdata);
      })
      .catch((err) => console.error(err));
  }, []);
  const [ga, setga] = React.useState();
  React.useEffect(() => {
    d &&
      d.map((res) => {
        if (res.id == pat.id) {
          setga(res);
          // console.log(res)
        }
      });
  }, [d]);
  // const [en, seten] = React.useState();
  // React.useEffect(() => {
  //   // firestore
  //   //   .collection(`patient/${pat.id}/encounter`)
  //   //   .get()
  //   //   .then((response) => {
  //   //     let fe = [];
  //   //     response.docs.forEach((document) => {
  //   //       const fetchedpat = {
  //   //         //id: document.id,
  //   //         ...document.data(),
  //   //       };
  //   //       fe.push(fetchedpat);
  //   //     });
  //   //     seten(fe);
  //   //     // console.log(fe);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.log(error);
  //   //   });
  //   FHIR.oauth2
  //     .ready()
  //     .then((client) => client.request('Encounter'))
  //     .then((res) => {
  //       // console.log('excuited.....')
  //       // console.log(res.entry);
  //       enn(res.entry);
  //       //console.log(res.entry);
  //     });
  // }, []);
  // const enn = (e) => {
  //   if (e && e) {
  //     const sortByDate = (e) => {
  //       const sorter = (a, b) => {
  //         return new Date(a.resource.period).getTime() - new Date(b.resource.period).getTime();
  //       };
  //       e.sort(sorter);
  //     };
  //     sortByDate(e);
  //     console.log(e[e.length - 1]);
  //   }
  // };
  //let data = en && en.map((res) => (res.data !== undefined ? JSON.parse(res.data) : null));
  return (
    <div
      style={{
        width: '100px',
        height: '40px',
        //,marginLeft:'auto',marginRight:'auto'
      }}
    >
      <GaugeChart id="gauge-chart2" nrOfLevels={20} percent={ga && ga.doctorsuggestion} />
    </div>
  );
};
export default Guage;
