import React, { FC, useMemo, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePatient } from '../PatientProvider';
import FHIR from 'fhirclient';
import classes from './PatientSnapshot.module.scss';
import { Avatar } from '@mui/material';
///
import { firebas as firebase } from '../MedicalNecessary/firebase';
// import FhirPatient from './patientfirebase'
import { patContext } from './patientfirebase';
import { encounterContext } from '../Navigation/encounterporvider';
import axios from 'axios';

const getPatientName = (name: Array<fhir.HumanName> = []): string => {
  const entry = name.find((n) => n.use === 'official') || name[0];
  return entry ? `${(entry.given || []).join(' ')} ${entry.family}` : 'No name';
};

const getPatientAddress = (address: Array<fhir.Address> = []): string => {
  const entry = address[0];
  return entry ? [entry.city, entry.state].filter((item) => !!item).join(', ') : 'No Address';
};
const firestore = firebase.firestore();

const PatientSnapshot: FC<{}> = () => {
  const patient = usePatient();
  const p = useContext(patContext);
  const { dataView } = useContext(encounterContext);
  //const patientdata = firestore.collection('patient');
  const [formValue, setFormValue] = React.useState(patient);
  const [daa, setdaa] = React.useState(formValue || '');
  const [encounterr, setencounerr] = React.useState('');

  const dl = [
    {
      resourceType: 'List',
      id: 'current-list',

      status: dataView && dataView.resource && dataView.resource.status,
      mode: 'working',
      title: 'Current List',
      code: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '52472-8',
            display: '',
          },
        ],
        text: 'Current List',
      },
      date: new Date(),
      source: {
        reference: `Patient/${formValue.id}`,
      },
      orderedBy: {
        coding: [
          {
            system: 'http://terminology.hl7.org/CodeSystem/list-order',
            code: 'entry-date',
          },
        ],
      },
      entry: [
        {
          item: {
            reference: `Encounter/${dataView && dataView.resource && dataView.resource.id}`,
          },
        },
      ],
    },
  ];

  const sendMessage = async () => {
    // e.preventDefault();
    let da: never[] = [];
    FHIR.oauth2
      .ready()
      .then((client) => client.request('Encounter'))
      .then((res) => {
        setencounerr(res.entry);
        da = res.entry;
        //console.log(da)
        //console.log(JSON.stringify(res))
        // console.log(res.entry)
      })

      .catch((err) => console.log(err));
    let coo: never[] = [];
    FHIR.oauth2
      .ready()
      .then((client) => client.request('Coverage'))
      .then((res) => {
        coo = res.entry;
      })

      .catch((err) => console.log(err));

    let data = JSON.stringify(formValue);
    // {
    //   console.log('executing......');
    // }
    // const config = {
    //   headers: {
    //     'Access-Control-Allow-Origin': '*', // you can also whitelist a specific domain like "http://127.0.0.1:4000"
    //     'Access-Control-Allow-Headers': 'Content-Type',
    //     'Access-Control-Allow-Methods': 'POST',
    //     'Access-Control-Max-Age': '3600',
    //     // "Access-Control-Allow-Origin": "*",
    //     // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    //   },
    // };
    // let datas = {
    //   id: daa.id,
    //   patient: data,
    //   encounter: da !== undefined ? JSON.stringify(da) : '',
    //   list: JSON.stringify(dl),
    //   coverage: JSON.stringify(coo) || '',
    //   enref: `Encounter/${dataView && dataView.resource && dataView.resource.id}`,
    //   patref: (daa && daa.name && daa.name[0].family) || '',
    // };
    axios

      .post('https://us-central1-smartonfhir-274312.cloudfunctions.net/mypa', {
        // .post('http://localhost:5001/smartonfhir-274312/us-central1/mypa',{
        id: daa.id,
        patient: data,
        encounter: da !== undefined ? JSON.stringify(da) : '',
        list: JSON.stringify(dl),
        coverage: JSON.stringify(coo) || '',
        enref: `Encounter/${dataView && dataView.resource && dataView.resource.id}`,
        patref: (daa && daa.name && daa.name[0].family) || '',
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    // var db = firestore;
    // const dbRef = db.collection('patient').doc(daa.id);
    // await dbRef.set({
    //   data: '',
    // });
    // await dbRef.collection('IDs').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Duplicates').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('OrphanedFiles').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('AllResources').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('CareEveryWhere').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Linked').doc().set({
    //   data: '',
    // });
    // await dbRef
    //   .collection('Lists')
    //   .doc()
    //   .set({
    //     data: JSON.stringify(dl),
    //   });
    // await dbRef.collection('Compose').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('AllDocuments').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('DocReference').doc().set({
    //   data: '',
    // });

    // await dbRef.collection('Medications').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Allgeries').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Conditions').doc().set({
    //   data: '',
    // });
    // await dbRef
    //   .collection('encounter')
    //   .doc()
    //   .set({
    //     data: da !== undefined ? JSON.stringify(da) : '',
    //   });

    // await dbRef.collection('Episodes').doc().set({
    //   data: '',
    // });

    // await dbRef.collection('Accounts').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Consents').doc().set({
    //   data: '',
    // });
    // await dbRef
    //   .collection('Coverage')
    //   .doc()
    //   .set({
    //     data: JSON.stringify(coo) || '',
    //   });
    // await dbRef.collection('Trash').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Tasks').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('CareTeams').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Media').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Index').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('SharedApps').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Imports').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Drafts').doc().set({
    //   data: '',
    // });
    // // await dbRef.collection('Archive').doc().set({
    // //   data: '',
    // // });
    // await dbRef.collection('inbox').doc().set({
    //   data: data,
    // });
    // await dbRef.collection('Linked').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Categories').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Patient').doc().set({
    //   data: '',
    // });
    // await dbRef.collection('Updates').doc().set({
    //   data: '',
    // });

    // ////// dont distrub
    // const umRef = db.collection('um_case_reviews').doc(daa.id);
    // await umRef.collection('md_review').doc().set({
    //   data: '',
    // });
    // await umRef.collection('um_review').doc().set({
    //   data: '',
    // });
    // await umRef.collection('ai_review').doc().set({
    //   data: '',
    // });
    // await umRef.set({
    //   id: daa.id,
    //   Priority: 'ǃ High',
    //   Patient: (daa && daa.name && daa.name[0].family) || '',
    //   LastReviewed: Date.now() || '',
    //   LastPAReviewed: '',
    //   NextReviewDue: '',
    //   Audit: '',
    //   Payer: '',
    //   SecondaryPayer: '',
    //   CLS: '',
    //   Status: 'Active',
    //   umsuggestion: 0,
    //   doctorsuggestion: 0,
    //   aiprediction: 0,
    //   encounter: `Encounter/${dataView && dataView.resource && dataView.resource.id}`,
    // });
  };

  const name = useMemo(() => getPatientName(patient.name), [patient]);
  const address = useMemo(() => getPatientAddress(patient.address), [patient]);
  let bb = p && p.map((res: { id: any } | null) => (res !== null ? res.id : ''));

  React.useEffect(() => {
    if (bb !== undefined) {
      if (bb.includes(patient.id)) {
        // console.log(bb,patient.id);
        // firestore.collection(`patient/${patient.id}/encounter`)
        //   .get()
        //   .then((response) => {
        //     const fe: { [x: string]: any; }[] = [];
        //     //let fe: { id: string }[] = [];
        //     response.docs.forEach((document) => {
        //       const fetchedpat = {
        //        // id: document.id,
        //         ...document.data(),
        //       };
        //       fe.push(fetchedpat);
        //     });
        //     a=fe
        //     console.log(fe);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });
        // console.log(true);
        //placed
        // console.log('excuited.....1')
        // sendMessage();
      } else {
        //console.log(false);
        sendMessage();
        //console.log('excuited.....2')
      }
    }
    //console.log(bb.includes(patient.id), bb, patient.id);
  }, []);

  return (
    <div className={classes['patient-snapshot']}>
      {/* {console.log(JSON.stringify(formValue))} */}
      {/* {console.log(formValue)} */}
      {/* <button onClick={sendMessage}>A</button> */}
      {/* <FontAwesomeIcon icon="user-circle" className={classes['patient-snapshot__photo']} /> */}
      <Avatar
        src="/broken-image.jpg"
        style={{ width: '60px', height: '60px', marginRight: '5px' }}
      />
      <div className={classes['patient-snapshot__info']}>
        <div className={classes['patient-name']}>{name}</div>

        <ul className={classes['patient-snapshot__list']}>
          <li>
            DOB: {patient.birthDate}&nbsp;({getAge(patient.birthDate)}&nbsp;years)
          </li>
          <li>Admin. Sex: {patient.gender}</li>
          {/* <li style={{display:'inline-block',float:'right'}}>Room:2403 02</li>
          <br/> */}
          <li style={{ display: 'inline-block' }}>MRN:W000123456 </li>
          {/* <li style={{ float: 'right' }}>&nbsp;&nbsp;&nbsp;LOS:1 day</li> */}
        </ul>
      </div>
    </div>
  );
};

export default PatientSnapshot;
function getAge(dateString: any) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
// export function getAge(birthday) {
//   if (birthday) {
//       let currentDate = moment();
//       let birthDate = moment(Date.parse(birthday));

//       let result = "";
//       let years = currentDate.diff(birthDate, 'years');
//       result += years + 'y ';
//       currentDate.subtract({ years });
//       let months = currentDate.diff(birthDate, 'months');
//       result += months + 'm ';
//       currentDate.subtract({ months });
//       let days = currentDate.diff(birthDate, 'days');
//       result += days + 'd';
//       return result;
//   } else {
//       return 'N/A'
//   }
// }

// await dbRef.set({
//   key: '',
// });
// await dbRef.collection('inbox').doc().set({
//   data: data,
// });
// await dbRef.collection('index').doc().set({
//   data: '',
// });
// await dbRef.collection('lists_resources').doc().set({
//   data: JSON.stringify(dl),
// });
// await dbRef.collection('collections').doc().set({
//   data: '',
// });

// await dbRef.collection('allergies').doc().set({
//   data: '',
// });

// await dbRef.collection(`clinical_documents`).doc().set({
//   data: '',
// });

// await dbRef.collection('diagnosis').doc().set({
//   data: '',
// });
// await dbRef.collection('demographics').doc().set({
//   data: '',
// });

// await dbRef.collection('external_partner_ccd').doc().set({
//   data: '',
// });
// await dbRef.collection('functional_status').doc().set({
//   data: '',
// });
// await dbRef.collection('immunization').doc().set({
//   data: '',
// });
// await dbRef.collection('intervention').doc().set({
//   data: '',
// });
// await dbRef.collection('lab_results').doc().set({
//   data: '',
// });
// await dbRef.collection('medications').doc().set({
//   data: '',
// });
// await dbRef.collection('plan_of_treatment').doc().set({
//   data: '',
// });
// await dbRef.collection('problems').doc().set({
//   data: '',
// });
// await dbRef.collection('procedures').doc().set({
//   data: '',
// });
// await dbRef.collection('social_history').doc().set({
//   data: '',
// });
// await dbRef.collection('vital_signs').doc().set({
//   data: '',
// });

// await dbRef.collection('vertical').doc().set({
//   data: '',
// });
// await dbRef.collection('archieve').doc().set({
//   data: '',
// });

// await dbRef
//   .collection('encounter')
//   .doc()
//   .set({
//     data:da!==undefined ?  JSON.stringify(da):'',
//   });
// // await dbRef.collection('list').doc().set({
// //   data: JSON.stringify(dl),
// // });
// await dbRef.collection('labs').doc().set({
//   data: '',
// });
// await dbRef.collection('tags').doc().set({
//   data: '',
// });
// await dbRef.collection('trash').doc().set({
//   data: '',
// });
// //console.log(coo);
// await dbRef
//   .collection('coverage')
//   .doc()
//   .set({
//     data: JSON.stringify(coo) || '',
//   });
