import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PatientSnapshot from 'components/PatientSnapshot';
import DropDown from 'components/DropDown';
import Parctitioner from '../practitionerrecord/practitionerrecord';
import classes from './Navigation.module.scss';
import { Button, Grid, Divider, Box } from '@mui/material';
import { encounterContext } from './encounterporvider';
import moment from 'moment';
import Guage from './guages';

type Option = {
  label: string;
  value: string;
};

const pathwayOptions = [
  { label: 'Medications', value: 'meds' },
  { label: 'Chart', value: 'chart' },
];

const Navigation: FC<{}> = () => {
  const [pathway, setPathway] = useState<Option | ReadonlyArray<Option> | null>(null);

  const onChangeHandler = (pathway: Option | ReadonlyArray<Option> | null): void => {
    setPathway(pathway);
  };
  const { dataView } = React.useContext(encounterContext);
  const dd = (r: string) => {
    var output = data.filter((res) => res.code == r);
    for (var i = 0; i < output.length; i++) {
      // console.log(output[i].display)
      return output[i].display;
    }
  };
  //let aaaa= dataView && dataView.resource && dataView.resource.class.code
  // let bbbb=aaaa=='AMD'?'ambulatory':
  //   aaaa=="EMER"?"emergency":
  //   aaaa=="FLD"?"'field":''
  return (
    // <nav className={classes.navigation}>
    //   <div className={classes['navigation__left-panel']}>

    //     <PatientSnapshot />
    //   </div>
    //   <div style={{ display: 'flex' }}>
    //     <Parctitioner />
    //   </div>

    // </nav>

    <Box sx={{ backgroundColor: 'whitesmoke' }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <Box sx={{ backgroundColor: 'white', height: '100%' }}>
            <PatientSnapshot />
            {/* {console.log(dataView)} */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box sx={{ backgroundColor: 'white', height: '100%', paddingLeft: 5 }}>
            <span style={{ fontSize: '11px', color: '#818181' }}>Date of Admission</span>
            <br />
            <span style={{ fontSize: '14px', color: '#303952' }}>
              {
                dataView && dataView.resource && dataView.resource.period.start.slice(0, -15)
                //getAge(dataView.resource.period.start)
              }
            </span>
            <br />
            <span style={{ fontSize: '11px', color: '#818181' }}>Status</span>
            <li style={{ float: 'right', fontSize: '11px', color: '#818181' }}>
              &nbsp;&nbsp;&nbsp;LOS:{' '}
              <span style={{ fontSize: '14px', color: '#303952' }}>
                {
                  dataView &&
                    dataView.resource &&
                    los(dataView.resource.period.start, dataView.resource.period.end)
                  //getAge(dataView.resource.period.start)
                }&nbsp;&nbsp;&nbsp;
              </span>
            </li>
            <br />
            <span style={{ fontSize: '14px', color: '#303952' }}>
              {dataView && dataView.resource && dd(dataView.resource.class.code)}
              {/* {bbbb} */}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box sx={{ backgroundColor: 'white', height: '100%', textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={{ fontSize: '11px', marginTop: '28px' }}>Obs</span>
              <Guage />
              <span style={{ fontSize: '11px', marginTop: '28px' }}>In</span>
            </div>
            <p style={{ marginLeft: '10px' }}>AI Predection</p>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box sx={{ backgroundColor: 'white', height: '100%', textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={{ fontSize: '11px', marginTop: '28px' }}>Obs</span>
              <Guage />
              <span style={{ fontSize: '11px', marginTop: '28px' }}>In</span>
            </div>
            <p style={{ marginLeft: '10px' }}>MD Review</p>
          </Box>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box sx={{ backgroundColor: 'white', height: '100%', textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <span style={{ fontSize: '11px', marginTop: '28px' }}>Obs</span>
              <Guage />
              <span style={{ fontSize: '11px', marginTop: '28px' }}>In</span>
            </div>
            <p style={{ marginLeft: '10px' }}>UM Review</p>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navigation;
function getAge(birthday: any) {
  if (birthday) {
    // console.log(birthday)
    let str = birthday.slice(0, -15);
    let currentDate = moment();
    let birthDate = moment(Date.parse(birthday));

    let result = '';
    let years = currentDate.diff(birthDate, 'years');
    result += years + 'y ';
    currentDate.subtract({ years });
    let months = currentDate.diff(birthDate, 'months');
    result += months + 'm ';
    currentDate.subtract({ months });
    let days = currentDate.diff(birthDate, 'days');
    result += days + 'd';
    return result;
    //return str;
  } else {
    return 'N/A';
  }
}
function los(date1: any | string | Date, date2: string | Date) {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;
  let a = date2 == undefined ? new Date() : date2;
  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(new Date(date1).valueOf() - new Date(a).valueOf());
  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
}

// function getAge(dateString: any) {
//   var today = new Date();
//   var birthDate = new Date(dateString);
//   var age = today.getFullYear() - birthDate.getFullYear();
//   var m = today.getMonth() - birthDate.getMonth();
//   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
//     age--;
//   }
//   return age;
// }
const data = [
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'AMB',
    display: 'ambulatory',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'EMER',
    display: 'emergency',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'FLD',
    display: 'field',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'HH',
    display: 'home health',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'IMP',
    display: 'inpatient encounter',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'ACUTE',
    display: 'inpatient acute',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'NONAC',
    display: 'inpatient non-acute',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'OBSENC',
    display: 'observation encounter',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'PRENC',
    display: 'pre-admission',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'SS',
    display: 'short stay',
  },
  {
    system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
    code: 'VR',
    display: 'virtual',
  },
];
