import React from 'react';
import {
  Autocomplete,
  TextField,
  Box,
  Grid,
  Card,
  Typography,
  Container,
  Divider,
  CardContent,
  Button,
  CardActions,
} from '@mui/material';
import './medical.scss';
import Guage from './guage';

const MDReview = () => {
  function generate(element: React.ReactElement) {
    return [0, 1, 2, 4, 5].map((value) =>
      React.cloneElement(element, {
        key: value,
      })
    );
  }

  return (
    <div>
      {/* <p className='test2'>OBSERVATION</p> */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <div className="moby">
            <div className="h21">
              <p className="cc">O B S E R V A T I O N</p>
            </div>
            <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>Review</p>
            <Autocomplete
              //size="small"
              //disablePortal
              id="combo-box-demo"
              options={aa}
              sx={{ width: 400 }}
              renderInput={(params) => (
                <TextField 
                //size="small" 
                {...params} label="Choose Diagnosis / Conditions" />
              )}
            />

            <Box sx={{ textAlign: 'center' }}>
              <p className="mdhe">01.Symptom or Specific Diagonis?</p>
            </Box>
            <Grid container spacing={1}>
              {first.map((res, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card sx={{ p: 1 }} className="cards">
                    <p>{res.name}</p>
                    {res.secondary.map((line) => (
                      <Typography variant="caption" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ textAlign: 'center' }}>
              <p className="mdhe">02.Diagnostic Testing or Defined Treatment?</p>
            </Box>
            <Grid container spacing={1}>
              {second.map((res, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card sx={{ p: 1 }} className="cards">
                    <p>{res.name}</p>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ textAlign: 'center' }}>
              <p className="mdhe">03.Chronicor Acute Diagonsis?</p>
            </Box>
            <Grid container spacing={1}>
              {third.map((res, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card sx={{ p: 1 }} className="cards">
                    <p>{res.name}</p>
                    {res.secondary.map((line) => (
                      <Typography variant="caption" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ textAlign: 'center' }}>
              <p className="mdhe">
                04.Severity of Chronic Condition:Anticipate recovery in &lt; 2MN or clearly ill?
              </p>
            </Box>
            <Grid container spacing={1}>
              {forth.map((res, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card sx={{ p: 1 }} className="cards">
                    <p>{res.name}</p>
                    {res.secondary.map((line) => (
                      <Typography variant="caption" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ textAlign: 'center' }}>
              <p className="mdhe">
                05.Medicare Time Requirement :Anticipate care will span Two Midnights?
              </p>
            </Box>
            <Grid container spacing={1}>
              {fifth.map((res, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <Card sx={{ p: 1 }} className="cards">
                    <p>{res.name}</p>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="moby">
            <div className="h221">
              <p className="cc">I N P A T I E N T</p>
            </div>
            <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>AI Suggestions</p>
            <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: '90px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#FA4900',
                    position: 'absolute',
                  }}
                >
                  OBS
                </span>
              </div>
              <div>
                <Guage />
              </div>
              <div style={{ marginTop: '90px', marginLeft: '-30px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: ' #005DFF',
                    position: 'absolute',
                  }}
                >
                  IN
                </span>
              </div>
            </div>
            <Divider />
          </div>
            <Box sx={{ p: 2 }}>
              <p
                style={{
                  fontSize: '14px',
                  padding: '0px !important',
                  color: '#3E4F7C',
                  fontWeight: 500,
                }}
              >
                Suggestion &nbsp;
                <span
                  style={{
                    backgroundColor: '#F34036',
                    borderRadius: '4px',
                    color: 'white',
                    padding: '2px',
                  }}
                >
                  5
                </span>
              </p>

              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Typography variant="caption" color="text.secondary">
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </Typography>
                </CardContent>
                {/* <CardActions> */}
                <Box sx={{ p: 1 }}>
                  <Button startIcon={<i className="fa fa-question-circle" />} size="small">
                    Learn More
                  </Button>
                  <div style={{ float: 'right' }}>
                    <i className="fa fa-trash" aria-hidden="true" />
                    &nbsp;&nbsp;
                  </div>
                </Box>

                {/* </CardActions> */}
              </Card>
            </Box>
            <Box sx={{ p: 2 }}>
              {generate(
                <Box sx={{ backgroundColor: 'white', mb: 1 }} className="ca">
                  <p style={{ padding: '2px' }}>
                    {' '}
                    <span style={{ color: '#F34036' }}>●</span> Lorem ipsum dolor sit amet,
                    consectetur adipd.
                  </p>
                </Box>
              )}
            </Box>
            <Box sx={{ p: 2 }}>
              <p
                style={{
                  fontSize: '14px',
                  padding: '0px !important',
                  color: '#3E4F7C',
                  fontWeight: 500,
                }}
              >
                UM Suggestions &nbsp;
                <span
                  style={{
                    backgroundColor: '#F34036',
                    borderRadius: '4px',
                    color: 'white',
                    padding: '2px',
                  }}
                >
                  5
                </span>
              </p>

              {generate(
                <Box sx={{ backgroundColor: 'white', mb: 1 }} className="ca">
                  <p style={{ padding: '2px' }}>
                    <span style={{ color: '#F34036',fontWeight:700 }}>●</span> Adam Davidson
                  </p>
                  <p style={{ marginLeft: '10px' }}>Patient: Thomas, Cameron</p>
                  <p style={{ marginLeft: '10px' }}>Subject: CDI QUERY</p>
                </Box>
              )}
            </Box>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default MDReview;

const first = [
  {
    name: 'Patient presents with symptoms that are the main reason for keeping the patient:',
    secondary: ['Chest Pain', 'Syncope/Dizziness', 'Abdominal Pain'],
  },
  {
    name: 'Patient has specific diagnosis requiring treatment:',
    secondary: ['MI', 'CVA', 'Pancreatitis'],
  },
];
const second = [
  {
    name: 'Physician orders focus on determining the cause of symptoms.',
  },
  {
    name: 'Physician orders focus on specific treatment requiring acute care hospitalization.',
  },
];
const third = [
  {
    name: 'Patient is being kept to treat a chronic illness',
    secondary: ['CHF', 'COPD', 'Asthma', 'HTN', 'Diabetes'],
  },
  {
    name: 'Patient is being kept to treat an acute illness',
    secondary: ['Pneumonia', 'Pyelonehritis', 'Cellulitis'],
  },
];
const forth = [
  {
    name:
      'Patient’s exacerbation of their chronic conditions is Not severe and expected to recover quickly',
    secondary: ['CHF', 'COPD', 'Asthma', 'HTN', 'Diabetes'],
  },
  {
    name:
      'Patient’s presenting symptoms demonstrate that their exacerbation of their chronic illness is severe.',
    secondary: ['CHF', 'COPD', 'Asthma', 'HTN', 'Diabetes'],
  },
];
const fifth = [
  {
    name:
      'Unsure if patient will need hospital care spanning two Midnights, inclusing time in ED. Place in Observation',
  },
  {
    name: 'Patient care will likely span Two Midnights, including time in ED, Consider inpatient',
  },
];

const aa = [
  { label: 'Abdominal Aortic Aneurysm' },
  { label: ' Abdominal pain' },
  { label: 'Abdominal Trauma Blunt' },
  { label: 'Abdominal Trauma Imaging' },
  { label: 'Abuse Elder Pediatric' },
  { label: 'Acetaminophen Poisoning' },
  { label: 'Achalasia' },
  { label: 'Acididosis' },
  { label: 'Acute Coronary Syndrome: Myocardial Infarction' },
  { label: 'Acyanotic Congenital Heart Disease' },
  { label: 'Adrenal Insufficiency' },
  { label: 'Agitation' },
  { label: 'Airway Adjuncts' },
  { label: 'Airway Management' },
  { label: 'Alcohol Poisoning' },
  { label: 'Alcohol Withdrawal' },
  { label: 'Altered Mental Status' },
  { label: 'Amebiasis' },
  { label: 'Amenorrhea' },
  { label: 'Amyotrophic Lateral Sclerosis' },
  { label: 'Amputation Traumatic Replantation' },
  { label: 'Anal Fissure' },
  { label: 'Anaphylaxis' },
  { label: 'Angioedema' },
  { label: 'Animal Bite' },
  { label: 'Ankle' },
  { label: 'Fracture & Or Dislocation' },
  { label: 'Ankle Sprain' },
  { label: 'Ankylosing Spondylitis' },
  { label: 'Anticholinergic Poisoning' },
  { label: 'Antidepressant Poisoning' },
  { label: 'Aphthous Ulcers' },
  { label: 'Arsenic Poisoning' },
  { label: 'Arterial Gas Embolism (AGE)' },
  { label: 'Arterial Occlusion' },
  { label: 'Arthritis Degenerative' },
  { label: 'Arthritis Juvenile Idiopathic' },
  { label: 'Arthritis Monoarticular' },
  { label: 'Arthritis Rheumatoid' },
  { label: 'Arthritis  Septic' },
  { label: 'Ascites' },
  { label: 'Asthma Adult / Pediatric' },
  { label: 'Babesiosis' },
  { label: 'Back Pain Tracheitis' },
  { label: 'Barbiturates Poisoning' },
  { label: 'Barotrauma' },
  { label: 'Bartholin Abscess' },
  { label: 'Bath Salts Synthetic Cathinones Poisoning' },
  { label: 'Bee Sting' },
  { label: 'Bells Palsy' },
  { label: 'Benzodiazepine Poisoning' },
  { label: 'Beta Blocker Poisoning' },
  { label: 'Biologic Weapons' },
  { label: 'Bipolar Disorder' },
  { label: 'Bladder Injury' },
  { label: 'Blow Out Fracture' },
  { label: 'Blunt Chest Trauma' },
  { label: 'Boerhaave Syndrome' },
  { label: 'Botulism' },
  { label: 'Bowel Obstruction Small & Large' },
  { label: 'Brady-arrhythmias' },
  { label: 'Bronchitis' },
  { label: 'Brugada Syndrome' },
  { label: 'Bundle Branch Blocks' },
  { label: 'Burns' },
  { label: 'Bursitis' },
  { label: 'Calcium Channel Blocker Poisoning' },
  { label: 'Carbamazepine Poisoning' },
  { label: 'Cardiac Arrest' },
  { label: 'Cardiac Pacemakers' },
  { label: 'Cardiac Reperfusion Therapy' },
  { label: 'Cardiac Testing' },
  { label: 'Cardiac Transplantation Complications' },
  { label: 'Cardiogenic Shock' },
  { label: 'Cardiomyopathy' },
  { label: 'Carpal Fractures' },
  { label: 'Cauda Equina Syndrome' },
  { label: 'Caustic Ingestion' },
  { label: 'Cavernous Sinus Thrombosis' },
  { label: 'Cellulitis' },
  { label: 'Central Retinal Artery Occlusion' },
  { label: 'Central Retinal Vein Occlusion' },
  { label: 'Cerebral Aneurysm' },
  { label: 'Cerebral Reperfusion Therapy' },
  { label: 'Cerebral Vascular Accident' },
  { label: 'Chancroid' },
  { label: 'Chemical Weapons Poisoning' },
  { label: 'Chest Pain' },
  { label: 'Cholangitis' },
  { label: 'Cholecystitis' },
  { label: 'Cholelithiasis' },
  { label: 'Chronic Obstructive Pulmonary Disease' },
  { label: 'Cirrhosis' },
  { label: 'Clavicle Fracture' },
  { label: 'Coagulopathy Reversal Nonwarfarin Agents' },
  { label: 'Cocaine Poisoning' },
  { label: 'Colon Trauma' },
  { label: 'Coma' },
  { label: 'Compartment Syndrome' },
  { label: 'Congestive Heart Failure' },
  { label: 'Conjunctivitis' },
  { label: 'Constipation' },
  { label: 'Contact Dermatitis' },
  { label: 'Corneal Abrasion' },
  { label: 'Corneal Burn' },
  { label: 'Corneal Foreign Body' },
  { label: 'Cor Pulmonale' },
  { label: 'Cough' },
  { label: 'Croup' },
  { label: 'Cushing Syndrome' },
  { label: 'Cutaneous Tinea' },
  { label: 'Infections' },
  { label: 'Cyanide Poisoning' },
  { label: 'Cyanosis' },
  { label: ' Cyanotic Congenital Heart   Disease' },
  { label: 'Cyclic  Vomiting' },
  { label: 'Cystic Fibrosis' },
  { label: ' Dacryocystitis  & Dacryoadenitis' },
  { label: 'Decompression Sickness' },
  { label: 'Deep Vein Thrombosis' },
  { label: 'Defibrillators Implantable' },
  { label: 'Delirium' },
  { label: 'Delivery Uncomplicated' },
  { label: 'Dementia' },
  { label: 'Dengue Fever' },
  { label: 'Dental Trauma' },
  { label: 'Dermatomyositis Polymyositis' },
  { label: 'Depression' },
  { label: 'Diabetes Insipidus' },
  { label: 'Diabetes Mellitus Juvenile' },
  { label: 'Diabetic Ketoacidosis' },
  { label: 'Diaper  Rash' },
  { label: 'Diaphragmatic Trauma' },
  { label: 'Diarrhea Adult / Pediatric' },
  { label: 'Digoxin Poisoning' },
  { label: 'Diplopia' },
  { label: 'Disseminated Intravascular Coagulation' },
  { label: 'Disulfiram Reaction' },
  { label: 'Diverticulitis' },
  { label: 'Dizziness' },
  { label: 'Drowning' },
  { label: 'Drug Withdrawal' },
  { label: 'Duodenal Trauma' },
  { label: 'Dysfunctional Uterine Bleeding' },
  { label: 'Dysphagia' },
  { label: 'Dyspnea' },
  { label: 'Ear Foreign Body' },
  { label: 'Eating  Disorder' },
  { label: 'Ectopic Pregnancy' },
  { label: 'Eczema Atopic Dermatitis' },
  { label: 'Edema' },
  { label: 'Ehrlichiosis' },
  { label: 'Elbow Injuries' },
  { label: 'Electrical Injury' },
  { label: 'Emergency Cesarean Section' },
  { label: 'Encephalitis' },
  { label: 'Endocarditis' },
  { label: 'Endometriosis' },
  { label: 'Epididymitis  & Orchitis' },
  { label: 'Epidural Abscess' },
  { label: 'Epiglottitis-Adult -Pediatric' },
  { label: 'Epistaxis' },
  { label: 'Erysipelas' },
  { label: 'Erythema Infectiosum' },
  { label: 'Erythema Multiforme' },
  { label: 'Erythema Nodosum' },
  { label: 'Esophageal Foreign Body' },
  { label: 'Esophageal Trauma' },
  { label: 'Ethylene Glycol Poisoning' },
  { label: 'External Ear Chondritis & Abscess' },
  { label: 'Extremity Trauma Penetrating' },
  { label: 'Facial Fractures' },
  { label: 'Failure to Thrive' },
  { label: 'Feeding Problems Pediatric' },
  { label: 'Fatigue' },
  { label: 'Febrile Seizure' },
  { label: 'Feeding Tube Complications' },
  { label: 'Femur Fracture' },
  { label: 'Fever, Adult' },
  { label: 'Fever,Pediatric' },
  { label: 'Fibrocyctic Breast Changes' },
  { label: 'Fibromyalgia' },
  { label: 'Flail Chest' },
  { label: 'Foot' },
  { label: 'Fracture' },
  { label: 'Forearm Fracture Distal Shaft' },
  { label: 'Fournier Gangrene' },
  { label: 'Fracture Open' },
  { label: 'Fracture Pediatric' },
  { label: 'Frostbite' },
  { label: ' Gallstone Ileus' },
  { label: 'Gangrene' },
  { label: 'Gastric Outlet Obstruction' },
  { label: 'Gastritis' },
  { label: 'Gastroenteritis' },
  { label: 'Gastro-esophageal Reflux Disease' },
  { label: 'Gastro-intestinal Bleeding' },
  { label: 'Geriatric Trauma' },
  { label: 'Ghb Poisoning' },
  { label: 'Giant Cell Arteritis (GCA) (Temporal Arteritis)' },
  { label: 'Giardisis' },
  { label: 'Glaucoma' },
  { label: 'Globe Rupture' },
  { label: 'Glomerulo-nephritis' },
  { label: 'Gonococcal Disease' },
  { label: 'Gout & Pseudogout' },
  { label: 'Granulo-cytopenia' },
  { label: 'Guillain - Barre Syndrome' },
  { label: 'Hallucinogen Poisoning' },
  { label: 'Hand Infection' },
  { label: 'Hazmat' },
  { label: 'Headache Cluster' },
  { label: 'Headache Migraine' },
  { label: 'Head Trauma Penetrating' },
  { label: 'Heart Murmur' },
  { label: 'HELLP Syndrome' },
  { label: 'Hematuria / Proteinuria' },
  { label: 'Hemoptysis' },
  { label: 'HemorrhagicShock' },
  { label: 'Hemothorax' },
  { label: 'Henoch SchOnlein Purpura' },
  { label: 'Hepatic Encephalopathy' },
  { label: 'Hepatitis' },
  { label: 'Herpes Genital' },
  { label: 'Herpes Simplex' },
  { label: 'Herpes Zoster' },
  { label: 'Hiccups' },
  { label: 'Hip Injury' },
  { label: 'Hirshsprung Disease' },
  { label: 'HIV / AIDS' },
  { label: 'Hordeolum & Chalazion' },
  { label: 'Human Bite' },
  { label: 'Humerus Fracture' },
  { label: 'Hydatiform Mole' },
  { label: 'Hydrocarbon Poisoning' },
  { label: 'Hydocele' },
  { label: 'Hydrocephalus' },
  { label: 'Hyperbaric Oxygen Therapy' },
  { label: 'Hypercalcemia' },
  { label: 'Hyperkalemia' },
  { label: 'Hypernatremia' },
  { label: 'Hyperosmolar Syndrome' },
  { label: 'Hyperpara-thyroidism' },
  { label: 'Hypertensive Emergencies' },
  { label: 'Hyperthermia' },
  { label: 'Hypertrophic Cardiomyopathy' },
  { label: 'Hyperthyroidism' },
  { label: 'Hyperviscosity Syndrome' },
  { label: 'Hyperventilation' },
  { label: 'Hyphema' },
  { label: 'Hypocalcemia' },
  { label: 'Hypoglycemia' },
  { label: 'Hypoglycemic Agent Poisoning' },
  { label: 'Hypokalemia' },
  { label: 'Hyponatremia' },
  { label: 'Hypopara-thyroidism' },
  { label: 'Hypothermia' },
  { label: 'Hypothyroidism' },
  { label: 'Idiopathic ThrombocytopenicPurpura' },
  { label: 'Immunizations' },
  { label: 'Immuno-suppression' },
  { label: 'Impetigo' },
  { label: 'Inborn Errors of Metabolism' },
  { label: 'Inflammatory Bowel Disease' },
  { label: 'Influenza' },
  { label: 'Intracerebral Hemorrhage' },
  { label: 'Intussusception' },
  { label: 'Iritis' },
  { label: 'Iron Poisoning' },
  { label: 'Irritable Bowel Syndrome' },
  { label: 'Irritable' },
  { label: 'Infant' },
  { label: 'Irritant Gas Exposure' },
  { label: 'Isoniazid Poisoning' },
  { label: 'Isopropanol Poisoning' },
  { label: 'Jaundice' },
  { label: 'Knee Dislocation' },
  { label: 'Knee Injuries ' },
  { label: 'ACL/PCL/MCL Meniscus' },
  { label: 'Labor' },
  { label: 'Labyrinthitis' },
  { label: 'Laceration Management' },
  { label: 'Laryngitis' },
  { label: 'Larynx Fracture' },
  { label: 'Lead Poisoning' },
  { label: 'Legg Calve Perth Disease' },
  { label: 'Leukemia' },
  { label: 'Leukocytosis' },
  { label: 'Lightning Injuries' },
  { label: 'Lithium Poisoning' },
  { label: 'Ludwig Angina' },
  { label: 'Lunate Dislocation' },
  { label: 'Lymphangitis' },
  { label: 'Malaria' },
  { label: 'Mallory Weiss Syndrome' },
  { label: 'Malrotation' },
  { label: 'Management of Violence' },
  { label: 'Mandibular Fractures' },
  { label: 'Marfan Syndrome' },
  { label: 'Marine Envenomation' },
  { label: 'Mastitis' },
  { label: 'Mastoiditis' },
  { label: 'MDMA Poisoning' },
  { label: 'Measles' },
  { label: 'Meckel Diverticulum' },
  { label: 'Meniere Disease' },
  { label: 'Menengitis' },
  { label: 'Meningo-coccemia' },
  { label: 'Mercury Poisoning' },
  { label: 'Mesenteric Ischemia' },
  { label: 'Metacarpal Injuries' },
  { label: 'Methanol Poisoning' },
  { label: 'Methemo-globinemia' },
  { label: 'Mitral Valve Prolapse' },
  { label: 'Molluscum Contagiosum' },
  { label: 'Monoamine Oxidase Inhibitor Poisoning' },
  { label: 'Mononucleosis' },
  { label: 'MRSA Community Acquired' },
  { label: 'Multiple Myeloma' },
  { label: 'Multiple Sclerosis' },
  { label: 'Multiple Trauma' },
  { label: 'Munchausen Syndrome' },
  { label: 'Mushroom Poisoning' },
  { label: 'Myasthenia Gravis' },
  { label: 'Myocardial Contusion' },
  { label: 'Myocarditis' },
  { label: 'Nasal Foreign Body' },
  { label: 'Nasal Fractures' },
  { label: 'Neck Injury By Strangulation / Hanging' },
  { label: 'Neck Trauma Blunt Anterior' },
  { label: 'Necrotizing Soft Tissue Infections' },
  { label: 'Necrotizing Ulcerative Gingivitis' },
  { label: 'Needlestick' },
  { label: 'Neonatal Jaundice' },
  { label: 'Neonatal Sepsis' },
  { label: 'Nephritic Syndrome' },
  { label: 'Nephrotic Syndrome' },
  { label: 'Neuroleptic Malignant Syndrome' },
  { label: 'Neuroleptic Poisoning' },
  { label: ' Noncardiogenic Pulmonary Edema' },
  { label: 'NonsteroidalAntiInflam-matory Poisoning' },
  { label: 'Nursemaid Elbow' },
  { label: 'Occulomotor Nerve Palsy' },
  { label: 'Opiate Poisoning' },
  { label: 'Opportunistic Infections' },
  { label: 'Optic Neuritis' },
  { label: 'Oral Candidiasis' },
  { label: 'Organo-phosphate Poisoning' },
  { label: 'Osgood Schlatter Disease' },
  { label: 'Osteogenesis Imperfecta' },
  { label: 'Osteomyelitis' },
  { label: 'Osteoporosis' },
  { label: 'Otitis Externa' },
  { label: 'Otitis Media' },
  { label: 'Otologic Trauma' },
  { label: 'Ovarian Cyst Torsion' },
  { label: 'Paget Disease' },
  { label: 'Pain (Principles / Meds / Comfort Care)' },
  { label: 'Pancreatic Trauma' },
  { label: 'Pancreatitis' },
  { label: 'Panic Attack' },
  { label: ' Paraphimosis' },
  { label: 'Parkinson Disease' },
  { label: 'Paronychia' },
  { label: 'Patellar Injuries' },
  { label: 'Patent Ductus Arteriosus' },
  { label: 'Pediatric Rash' },
  { label: 'Pediatric Seizure' },
  { label: 'Pediatric Trauma' },
  { label: 'Pediatric Urinary Tract Infection' },
  { label: 'Pediatric Vomiting' },
  { label: 'Pediculosis' },
  { label: 'Pelvic Fracture' },
  { label: 'Pelvic Inflammatory Disease' },
  { label: 'Pemphigus' },
  { label: 'Phimosis' },
  { label: 'Pityriasis Rosea' },
  { label: 'Placental Abruption' },
  { label: 'Placenta' },
  { label: 'Previa' },
  { label: 'Plant Poisoning' },
  { label: 'Pleural Effusion' },
  { label: 'Penetrating Chest Trauma' },
  { label: 'Penile Shaft Fracture' },
  { label: 'Peptic Ulcer' },
  { label: 'Perforated Viscous' },
  { label: 'Pericardial Effusion Tamponade' },
  { label: 'Pericarditis' },
  { label: 'Perilunate Dislocation' },
  { label: 'Pneumocystis' },
  { label: 'Pneumonia' },
  { label: 'Pneumo-mediastinum' },
  { label: 'Pneumonia - Adult / Pediatric' },
  { label: ' Poisoning' },
  { label: 'Poisoning Antidotes' },
  { label: 'Poisoning Gastric Decontamination' },
  { label: 'Poisoning Toxidromes' },
  { label: 'Periodic' },
  { label: 'Paralysis' },
  { label: 'Periorbital & Orbital Cellulitis' },
  { label: 'Peripartum Cardiomyopathy' },
  { label: 'Peripheral Neuropathy' },
  { label: 'Peripheral Vascular Disease' },
  { label: 'Perirectal Abscess' },
  { label: 'Polio' },
  { label: 'Polycythemia' },
  { label: 'Polyneuropathy' },
  { label: 'Postpartum Hemorrhage' },
  { label: 'Postpartum Infection' },
  { label: 'Preeclampsia Eclampsia' },
  { label: 'Peritonsillar Abscess' },
   { label: 'Pertussis' },
  { label: 'Phalangeal Injuries Foot' },
  { label: 'Phalangeal Injuries Hand' },
  { label: 'Pharyngitis' },
  { label: 'Phencyclidine' },
  { label: 'Poisoning' },
  { label: 'Phenytoin Poisoning' },
  { label: 'Pregnancy Trauma' },
  { label: 'Priapism' },
  { label: 'Prolonged QT Syndrome' },
  { label: 'Prostatitis' },
  { label: 'Pruritis' }, 
  { label: 'Pseudotumor Cerebri' },
  { label: 'Psoriasis' },
  { label: 'Psychiatric Commitment' },
  { label: 'Psychosis Acute' },
  { label: 'Psychosis Medical vs Psychiatric' },
  { label: 'Pulmonary Contusion' },
  { label: 'Pulmonary Edema' },
  { label: 'Pulmonary Embolism' },
  { label: 'Purpura' },
  { label: 'Pyelonephritis' },
  { label: 'Pyloric Stenosis Rabies' },
  { label: 'Radiation Injury' },
  { label: 'Rash' },
  { label: 'Reactive Arthritis' },
  { label: 'Rectal Foreign Body' },
  { label: 'Rectal Prolapse' },
  { label: 'Rectal Trauma' },
  { label: 'Red Eye' },
  { label: 'Renal Calculus' },
  { label: 'Renal Failure(Acute Kidney Injury)' },
  { label: 'Renal Injury' },
  { label: 'Respiratory Distress' },
  { label: 'Resuscitation Neonate / Pediatric' },
  { label: 'Retina Detachment' },
  { label: 'Retro-Orbital Hematoma' },
  { label: 'Retropharyngeal Abscess' },
  { label: 'Reye Syndrome' },
  { label: 'Rhabdomyolysis' },
  { label: 'Rheumatic Fever' },
  { label: 'Rib Fracture' },
  { label: 'Ring / Constricting Band' },
  { label: 'Removal' },
  { label: 'Rocky Mountain' },
  { label: 'Spotted Fever' },
  { label: 'Roseola' },
  { label: 'Rubella ' },
  { label: 'Sacral Fracture' },
  { label: 'Salicylate Poisoning' },
  { label: 'Scabies' },
  { label: 'Schizophrenia' },
  { label: 'Sciatica -Herniated Disc' },
  { label: 'Scorpion Sting' },
  { label: 'Seborrheic Dermatitis' },
  { label: 'Seizure Adult/Pediatric' },
  { label: 'Sepsis' },
  { label: 'Serotonin Syndrome (Drug Induced)' },
  { label: 'Serum Sickness' },
  { label: 'Sexual Assault' },
  { label: 'Shock' },
  { label: 'Shoulder Dislocation' },
  { label: 'Sickle Cell Disease' },
  { label: 'Sick Sinus Syndrome' },
  { label: 'Sinusitis (Rhinosinusitis)' },
  { label: 'Skin Cancer' },
  { label: 'Sleep Apnea' },
  { label: 'Slipped Capital Femoral Epiphysis' },
  { label: ' Small Bowel Injury' },
  { label: 'Smoke Inhalation' },
  { label: 'Snake Envenomation' },
  { label: 'Spider Bite Black Widow' },
  { label: 'Spider Bite Brown Recluse' },
  { label: 'Spinal Cord Syndromes' },
  { label: 'Spinal Injury Cervical Adult /Pediatric' },
  { label: 'Spinal Injury Coxccyx' },
  { label: 'Spinal Injury Lumbar' },
  { label: 'Spinal Injury Thoracic' },
  { label: 'Splenic Injury' },
  { label: 'Spondylolysis / Spondylolisthesis' },
  { label: 'Spontaneous Bacterial' },
  { label: 'Peritonitis Sporotrichosis' },
  { label: 'Staphylococcal' },
  { label: 'Scalded Skin Syndrome' },
  { label: 'Sterno-Clavicular Joint Injury' },
  { label: 'Stevens -Johnson Syndrome' },
  { label: 'Streptococcal Disease' },
  { label: 'Stridor' },
  { label: 'Subarachnoid Hemorrhage' },
  { label: 'Sudden Infant Death Syndrome(SIDS)' },
  { label: 'Suicide Risk Evaluation' },
  { label: 'Supraventricular Tachycardia' },
  { label: 'Sympathomimetic Poisoning' },
  { label: 'Syncope' },
  { label: 'Syndrome of Inappropriate Secretion of Antidiuretic Hormone(SIADH)' },
  { label: 'Syphilis' },
  { label: 'Systemic Lupus Erythematosus' },
  { label: 'Tachydys- rhythmias' },
  { label: 'Taser Injuries' },
  { label: 'Temporal Mandibular Joint Injury Syndrome' },
  { label: 'Tendonitis' },
  { label: 'Tendon Laceration' },
  { label: 'Tenosynovitis' },
  { label: 'Testicular' },
  { label: 'Torsion' },
  { label: 'Tetanus' },
  { label: 'Theophylline Poisoning' },
  { label: 'Thoracic Outlet Syndrome' },
  { label: 'Thumb Fracture' },
  { label: 'Tibial-Fibular Shaft Fracture' },
  { label: 'Tick Bite' },
  { label: 'Toluene Poisoning' },
  { label: 'Toothache' },
  { label: 'Torticollis' },
  { label: 'Toxic Epidermal Necrolysis' },
  { label: 'Toxic Shock Syndrome' },
  { label: 'Toxic Synovitis' },
  { label: 'Toxoplasmosis' },
  { label: 'Transfusion Complications' },
  { label: 'Transient Global Amnesia' },
  { label: 'Transient Ischemic Attack(TIA)' },
  { label: 'Transplant Rejection' },
  { label: 'Traumatic Aortic Rupture' },
  { label: 'Trichomonas' },
  { label: 'Tricyclic Antidepressant Poisoning' },
  { label: 'Trigeminal Neuralgia' },
  { label: 'Tuberculosis' },
  { label: 'Tularemia' },
  { label: 'Tumor Compression Syndromes' },
  { label: 'Tympanic Membrane Perforation' },
  { label: 'Ultraviolet Keratitis' },
  { label: 'Urethral Trauma' },
  { label: 'Urethritis' },
  { label: 'Urinary Retention' },
  { label: 'Urinary Tract Fistula' },
  { label: 'Urinary Tract Infections Adult/Pediatric' },
  { label: 'Urticaria' },
  { label: 'Uvulitis' },


];

