import React from 'react';
import { FhirResource, fhirVersions } from 'fhir-react';
const Orders=()=>{
    //const fhirResource = JSON.parse(orders);

return(
    <div>
 <FhirResource
      fhirResource={orders}
      fhirVersion={fhirVersions.R4}
    //   fhirIcons={<img
    //     src={require('./dstu2/resources/condition/condition.svg')}
    //     alt="header icon"
    //   />}
    //   withCarinBBProfile
    />
    </div>
)
}
export default Orders;
const orders={
    "resourceType": "Order",
    "id": "example",
    "text": {
      "status": "generated",
      "div": "<div>Request for Prescription (on patient Donald DUCK @ Acme Healthcare, Inc. MR = 654321)</div>"
    },
    "date": "2012-12-28T09:03:04+11:00",
    "subject": {
      "reference": "Patient/pat2"
    },
    "source": {
      "reference": "Practitioner/example"
    },
    "reasonCodeableConcept": {
      "text": "Standard admission testing"
    },
    "when": {
      "fhir_comments": [
        "  Institution local code meaning \"do this today\"  "
      ],
      "code": {
        "coding": [
          {
            "system": "http://acme.com/codes/request-priority",
            "code": "today"
          }
        ]
      }
    },
    "detail": [
      {
        "reference": "MedicationOrder/example"
      }
    ]
  }