import React, { useRef, useState } from 'react'
import './medical.scss'

// import firebase from 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
// import 'firebase/analytics'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { Button, Container, Grid, TextField } from '@mui/material'
import ScrollBar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
// firebase.initializeApp({
//     apiKey: 'AIzaSyDj1MDjk4XJ5KL5h35MNJwYAvx3ZbCcDu8',
//     authDomain: 'platform-gateway-b601f.firebaseapp.com',
//     projectId: 'platform-gateway-b601f',
//     storageBucket: 'platform-gateway-b601f.appspot.com',
//     messagingSenderId: '1035682625980',
//     appId: '1:1035682625980:web:5218d9fc01aa2b32ba93b9',
//     measurementId: 'G-2503827391',
// })
import {firebas as firebase} from './firebase'
const auth = firebase.auth()
const firestore = firebase.firestore()
//const analytics = firebase.analytics();

function Messages() {
    const [user] = useAuthState(auth)

    return (
        <div className="App">
            <Container maxWidth="lg">
                <header className='header'>
                    <SignOut />
                </header>

                <section>{user ? <ChatRoom /> : <SignIn />}</section>
            </Container>
        </div>
    )
}

function SignIn() {
    const signInWithGoogle = () => {
        const provider = new firebase.auth.GoogleAuthProvider()
        auth.signInWithPopup(provider)
    }
    const signInAnonymously = () => {
        auth.signInAnonymously()
            .then((res) => {
                // Signed in..
                console.log(res)
            })
            .catch((error) => {
                var errorCode = error.code
                var errorMessage = error.message
            })
    }
    return (
        <>
            <Button variant='outlined' color='primary' style={{alignItems:'center'}} onClick={signInAnonymously}>
            Anonymously
            </Button>
        </>
    )
}

function SignOut() {
    return (
        auth.currentUser && (<div></div>
            // <Button
            //     variant="outlined"
            //     size="small"
            //     onClick={() => auth.signOut()}
            //     style={{float:'right'}}
            // >
            //     Sign Out
            // </Button>
        )
    )
}

function ChatRoom() {
    const dummy = useRef()
    const messagesRef = firestore.collection('messages')
    const query = messagesRef.orderBy('createdAt').limit(25)

    const [messages] = useCollectionData(query, { idField: 'id' })

    const [formValue, setFormValue] = useState('')

    const sendMessage = async (e) => {
        e.preventDefault()

        const { uid, photoURL } = auth.currentUser

        await messagesRef.add({
            text: formValue,
            createdAt: firebase.firestore.FieldValue && firebase.firestore.FieldValue.serverTimestamp() || new Date(),
            uid,
            photoURL,
        })

        setFormValue('')
        dummy.current.scrollIntoView({ behavior: 'smooth' })
    }

    return (
        <>
            <div className="chatplace">
                <ScrollBar component="div">
                    <div className="chatcontent">
                        <main>
                            {messages &&
                                messages.map((msg) => (
                                    <ChatMessage key={msg.id} message={msg} />
                                ))}

                            <span ref={dummy}></span>
                        </main>
                    </div>
                </ScrollBar>
            </div>
            <header className='header1'>
            <form onSubmit={sendMessage}>
                {/* <input
                    value={formValue}
                    onChange={(e) => setFormValue(e.target.value)}
                    placeholder="say something nice"
                /> */}
                <Grid container>
                    <Grid item xs={12} sm={10}>
                        <TextField
                            value={formValue}
                            fullWidth
                            variant="outlined"
                            size="small"
                            onChange={(e) => setFormValue(e.target.value)}
                            placeholder="message"
                            label="message"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                    &nbsp;<Button
                            type="submit"
                            color="primary"
                            variant="outlined"
                            disabled={!formValue}
                        >
                            send
                        </Button>
                    </Grid>
                </Grid>
            </form>
            </header>
        </>
    )
}


function ChatMessage(props) {
    const { text, uid, photoURL } = props.message

    const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received'

    return (
        <>
            <div className={`message ${messageClass}`}>
                <img
                    className="imgg"
                    src={photoURL || '/static/images/user.jpg'}
                />
                <p className="p">{text}</p>
            </div>
        </>
    )
}

export default Messages
