import React, { FC } from 'react';
import {
  Tabs,
  Tab,
  Box,
  Grid,
  ListItemText,
  Paper,
  //Stack,
  //Badge,
  TextField,
  FormHelperText,
  //OutlinedInput,
  //MobileStepper,
  //FormControl,
  //InputLabel,
  //Select,
  MenuItem,
  //Autocomplete,
  //Card,
  Divider,
  //IconButton,
  //InputAdornment,
  Button,
  //Container,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import Insurance from './insuranceinfo';
// import { useTheme } from '@mui/material';
// import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';
import './medical.scss';
import MDreview from './mdreview';
import Ai from './ai';
import UMReview from './umreview';
import Chats from './chat';
import FhirResurceList from './FhirResourceList'
import Cshell from '../../components/chatapp/containers/shell/ChatShell';
import Query from '../query/Querys';
import Guage from './guage';
import Documents from './Query'
import { encounterContext } from '../Navigation/encounterporvider';
import Orders from './orders'
const options = ['ML Tree', 'Millmen', 'InterQual'];
//import { usePatient } from '../PatientProvider';


type PatientRecordProps = {
  resources: ReadonlyArray<Record<string, any>>;
};

const getResourceByType = (patientRecord: ReadonlyArray<any>, resourceType: string) => {
  return patientRecord.filter((resource) => resource.resourceType === resourceType);
};
//const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const MedicalNecessary: FC<PatientRecordProps> = ({ resources }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //const classes = useStyles();
  // const theme = useTheme();
  // const [activeStep, setActiveStep] = React.useState(0);
  // const maxSteps = tutorialSteps.length;
  // const handleStepChange = (step: number) => {
  //   setActiveStep(step);
  // };
  ///dropdown
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { dataView } = React.useContext(encounterContext);
  const dd = (r: string) => {
    var output = data.filter((res) => res.code == r);
    for (var i = 0; i < output.length; i++) {
      // console.log(output[i].display)
      return output[i].display;
    }
  };
  return (
    <Box sx={{ p: 1 }}>
      {/* {console.log(dataView && dataView.resource && dataView.resource.classHistory)} */}
      {/* <div style={{ backgroundColor: 'rgba(244,244,244,1)' }}>
        <span style={{ padding: '0.01rem' }}></span>
      </div> */}
      <Divider />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
  scrollButtons="auto">
          <Tab
            label={
              <div>
                <img
                  src="/static/images/Dashboard icon.svg"
                  alt="a"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                Dashboard{' '}
              </div>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <div>
                <img
                  src="/static/images/MD Review icon.svg"
                  alt="b"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                MD Review{' '}
              </div>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <div>
                <img
                  src="/static/images/UM Review icon.svg"
                  alt="c"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                UM Review{' '}
              </div>
            }
            {...a11yProps(2)}
          />
          <Tab
            label={
              <div>
                <img
                  src="/static/images/AI Prediction icon.svg"
                  alt="hh"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                AI Prediction{' '}
              </div>
            }
            {...a11yProps(3)}
          />
          <Tab
            label={
              <div>
                <img
                  src="/static/images/Certificate icon.svg"
                  alt="ag"
                  style={{ verticalAlign: 'middle' }}
                />
                Certificate{' '}
              </div>
            }
            {...a11yProps(4)}
          />
          <Tab
            label={
              <div>
                <img
                  src="/static/images/Knowledge icon.svg"
                  alt="ad"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                Knowledge{' '}
              </div>
            }
            {...a11yProps(5)}
          />
          <Tab
            label={
              <div>
                <img
                  src="/static/images/Queries icon.svg"
                  alt="da"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                Queries{' '}
              </div>
            }
            {...a11yProps(6)}
          />
          <Tab
            label={
              <div>
                <img
                  src="/static/images/Chat icon.svg"
                  alt="ha"
                  style={{ verticalAlign: 'middle' }}
                />{' '}
                Chat{' '}
              </div>
            }
            {...a11yProps(7)}
          />
           <Tab
            label={
              <div>
                {/* <img
                  src="/static/images/Chat icon.svg"
                  alt="ha"
                  style={{ verticalAlign: 'middle' }}
                /> */}
              Patient Info
              </div>
            }
            {...a11yProps(8)}
          />
              <Tab
            label={
              <div>
                {/* <img
                  src="/static/images/Chat icon.svg"
                  alt="ha"
                  style={{ verticalAlign: 'middle' }}
                /> */}
              Clinical Info
              </div>
            }
            {...a11yProps(9)}
          />
          <Tab
            label={
              <div>
                {/* <img
                  src="/static/images/Chat icon.svg"
                  alt="ha"
                  style={{ verticalAlign: 'middle' }}
                /> */}
              Orders
              </div>
            }
            {...a11yProps(10)}
          />
            <Tab
            label={
              <div>
                {/* <img
                  src="/static/images/Chat icon.svg"
                  alt="ha"
                  style={{ verticalAlign: 'middle' }}
                /> */}
              Documents
              </div>
            }
            {...a11yProps(11)}
          />
        </Tabs>
       
      
      </Box>
      <TabPanel value={value} index={0}>
        <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>INSURANCE INFORMATION</p>
        <Insurance />
        <br />
        <Divider />
        <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>ADMISSION CLASS</p>
        {/* <div id="flowBoxes">
      <div className="right">Diersoort / I&amp;R</div>
      <div className="left right active">Model</div>
      <div className="left right">Varianten</div>
      <div className="left right">Bedrukkingen</div>
      <div className="left">Bevestiging</div>
</div> */}

        <Grid container spacing={1} direction="row">

          {dataView && dataView.resource && dataView.resource.classHistory == undefined ? (
          <span>&nbsp;&nbsp;
              {dataView.resource.period.start.slice(0, -15)}
              <br />
              &nbsp;&nbsp;
              <div id="flowBoxes">
              <div className="right active">
              {dataView && dataView.resource && dd(dataView.resource.class.code)}
              </div>
              </div>
            </span>
          ) : (
            dataView &&
            dataView.resource &&
            dataView.resource.classHistory &&
            dataView.resource.classHistory.map((res: any, index: number) => (
              <Grid item key={index}>
                 <div id="flowBoxes">
                   <span style={{fontSize:'12px'}}>
                {res.period.start.slice(0, -15)}
                &nbsp;
                {new Date(res.period.start).toLocaleTimeString('en-US')}
                </span>
                <br />
               
                <div className={index==0?"right":`left right ${index==dataView.resource.classHistory.length-1 ?'active':''} `}>
                {res.class.display}
                </div>
                </div>
                {/* <img src="static/images/ER arrow.svg" alt="d" /> */}
              </Grid>
            ))
          )}
        </Grid>
    
        {/* <table>
          <thead>
            <tr>
              <td className="t">&nbsp;&nbsp;03-20-2020</td>
              <td className="t">&nbsp;&nbsp;03-20-2020</td>
              <td className="t">&nbsp;&nbsp;03-20-2020</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="trow">
                <span style={{ marginLeft: '45px' }} className="ss">
                  ER
                </span>
                <img src="static/images/ER arrow.svg" alt="d" />{' '}
              </td>
              <td className="trow">
                <span style={{ marginLeft: '28px' }} className="ss">
                  Observation
                </span>{' '}
                <img src="static/images/Observation arrow.svg" alt="df" />
              </td>
              <td className="trow">
                <span style={{ marginLeft: '28px' }} className="ss">
                  In patient
                </span>
                <img src="static/images/Inpatient arrow.svg" alt="hd" />
              </td>
            </tr>
          </tbody>
        </table> */}
        <br />
        <Divider />
        <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>ADMISSION STATUS</p>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <td className="thead">Service</td>
              <td className="thead">Attending of Record</td>
              <td className="thead">Primary Diagnosis</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="trow">13/05/2020 (Day #1)</td>
              <td className="trow">Lister, Joseph, MD</td>
              <td className="trow">Acute ischemic heart disease</td>
            </tr>
          </tbody>
        </table>
        <br />

        <Divider />
        <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}> MD Review</p>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={4}>
            <FormHelperText>Reviewed by</FormHelperText>
            <TextField
              //hiddenLabel
              // label="Reviewed by"
              placeholder="Reviewed by"
              margin="dense"
              size="small"
              variant="outlined"
              // inputProps={{ 'aria-label': 'description'}}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <FormHelperText>Date and Time of Review</FormHelperText>
            <TextField
              //hiddenLabel
              //label="Date"
              placeholder="Date"
              margin="dense"
              size="small"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4} md={4}>
              <FormHelperText>Time</FormHelperText>
              <TextField
                //hiddenLabel
               // label="time"
                placeholder="Time"
                margin="dense"
                size="small"
                variant="outlined"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> */}
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={4}>
            <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>CRITERIAL SOURCE</p>
            <Paper sx={{ width: '100px' }}>
              <List
                component="nav"
                aria-label="Device settings"
                // sx={{ bgcolor: 'background.paper' }}
              >
                <ListItem
                  button
                  id="lock-button"
                  aria-haspopup="listbox"
                  aria-controls="lock-menu"
                  aria-label="when device is locked"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClickListItem}
                >
                  <ListItemText primary={options[selectedIndex]} />
                </ListItem>
              </List>
            </Paper>
            <Menu
              id="lock-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
              }}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={option}
                  selected={index === selectedIndex}
                  onClick={(event) => handleMenuItemClick(event, index)}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>

            {/* <FormControl fullWidth margin="dense" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-label">Criterial Source</InputLabel>
                <Select
                  label="Criterial Source"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  <MenuItem value={10}>Manul</MenuItem>
                  <MenuItem value={20}>ML Tree</MenuItem>
                  <MenuItem value={30}>Melmen</MenuItem>
                  <MenuItem value={40}>InterQual</MenuItem>
                </Select>
              </FormControl> */}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>STATUS</p>
            <img src="static/images/Outpatient icon.svg" alt="yt" />
            <img src="static/images/Obsrvation icon.svg" alt="ys" />
            <img src="static/images/Short stay Active icon.svg" alt="yd" />
            <img src="static/images/Inpatient icon.svg" alt="yg" />

            {/* <FormControl fullWidth margin="dense" size="small" variant="outlined">
                <InputLabel id="demo-simple-select-label1">Doctor Decission</InputLabel>
                <Select
                  label="Doctor Decission"
                  labelId="demo-simple-select-label1"
                  id="demo-simple-select"
                >
                  <MenuItem value={10}>Out patient</MenuItem>
                  <MenuItem value={20}>Observation</MenuItem>
                  <MenuItem value={30}>Short Stay</MenuItem>
                  <MenuItem value={40}>In-Patient</MenuItem>
                </Select>
              </FormControl> */}
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>AUDIT RISK</p>
            {/* <img src="static/images/Risk dial.svg" /> */}
            {/* <div style={{ display: 'flex', justifyContent: 'center',width: '40%' }}>
              <span style={{ fontSize: '11px', marginTop: '97px',  }}>OBS</span>
              <Guage />
              <span style={{ fontSize: '11px', marginTop: '97px' ,position: 'absolute', }}>IN</span>
            </div> */}
            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: '90px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,

                    position: 'absolute',
                  }}
                >
                  OBS
                </span>
              </div>
              <div>
                <Guage />
              </div>
              <div style={{ marginTop: '90px', marginLeft: '-30px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,

                    position: 'absolute',
                  }}
                >
                  IN
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
        <br />
        <Divider />
        <Box sx={{ p: 2 }}>
          <p style={{ color: '#3E4F7C', fontSize: '14px', fontWeight: 500 }}>
            ADMISSION CERTIFICATE
          </p>
          <p style={{ fontSize: '14px', color: '#6D7278' }}>
            I certificed that i have admitted the patient as an in-patient and expect that the
            patient will require continual hospitaliztion as an in-patient in accordance with the
            Two-Midnight benchmark under. 42 CFR 412.3. I have provided the active diagnosis, plan
            of care and clinical concerns that i except will require care for Two (2) or more
            midnights with in this notes.
          </p>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="I agree with certificate"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="I do not certify ( Patient is in Observation )"
            />
          </FormGroup>
          <Button variant="contained">Place Inpatient Order</Button>&nbsp;
          <Button variant="contained">Upload to EMR</Button>
        </Box>
        {/* <Box sx={{ background: 'rgba(244,244,244,1)', p: 1 }}>
            <AutoPlaySwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {tutorialSteps.map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <div>
                      <p style={{ fontSize: '14px' }}>{step.label}</p>
                      <p style={{ fontSize: '14px' }}>{step.label1}</p>
                      <p>{step.label3}</p>
                    </div>
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              sx={{ display: 'flex', flexDirection: 'column', background: 'rgba(244,244,244,1)' }}
              steps={maxSteps}
              position="static"
              variant="dots"
              activeStep={activeStep}
              nextButton={''}
              backButton={''}
            />
          </Box>
        </Paper>
        <br />
        <Paper sx={{ p: 1 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
            <div style={{ display: 'inline-block' }}>
              <p>
                <i className="fa fa-user" aria-hidden="true" /> Utilization Manager Review
              </p>
            </div>
            <div>
              <Badge badgeContent={0} color="primary">
                <p>Suggestions</p>
              </Badge>
            </div>
          </Stack>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3}>
              <ListItemText
                primary={<span style={{ color: '#818181', fontSize: '12px' }}>Reviewer Name</span>}
                secondary={<span style={{ color: '#000000', fontSize: '12px' }}>Sample name</span>}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ListItemText
                primary={<span style={{ color: '#818181', fontSize: '12px' }}>Criteria</span>}
                secondary={<span style={{ color: '#000000', fontSize: '12px' }}>MDM</span>}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ListItemText
                primary={<span style={{ color: '#818181', fontSize: '12px' }}>Recommendation</span>}
                secondary={<span style={{ color: '#000000', fontSize: '12px' }}>99213</span>}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ListItemText
                primary={<span style={{ color: '#818181', fontSize: '12px' }}>Description</span>}
                secondary={
                  <span style={{ color: '#000000', fontSize: '12px' }}>
                    Office or other outpatient visit for the..
                  </span>
                }
              />
            </Grid>
          </Grid>
        </Paper>
        <br />
        <h3>AI Predection</h3>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={4} md={4}>
            <p>Prediction Score</p>
            <h3>85%</h3>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <p>Prediction Drift</p>
            <img src="static/images/chart.png" alt="img" width="100px"></img>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <p>Factors Contributing Score </p>
            <ul>
              <li>
                <span style={{ color: '#0091FF' }}>61%</span> O2 Deice is greater than Nasal cannula
              </li>
              <li>
                <span style={{ color: '#0091FF' }}>9%</span>RR Max in last 12 hr is 36
              </li>
              <li>
                <span style={{ color: '#0091FF' }}>7%</span> Blood urea nitrogen Last is 39
              </li>
              <li>
                <span style={{ color: '#0091FF' }}>4%</span> C-reactive protein Last is 256
              </li>
            </ul>
          </Grid>
        </Grid> */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MDreview />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UMReview />
        {/* <Grid container justifyContent="flex-start" alignItems="stretch">
          <Grid item xs={12} sm={5}>
            <img src="static/images/ss.png" alt="img" width="60%"></img>
            <br />
            <TextField
              // hiddenLabel
              //  margin='normal'
              size="small"
              label="Name, skill or speciality"
              placeholder="Name, skill or speciality"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <i className="fa fa-search" aria-hidden="true" />
                  </InputAdornment>
                ),
              }}
            />
           

            <Box sx={{ p: 1 }}></Box>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Specialist</td>
                  <td>
                    <span style={{ color: '#007AFF' }}>Cardology </span>
                  </td>
                  <td>
                    <i className="fa fa-angle-right"></i>
                  </td>
                </tr>

                <tr>
                  <td>Organization</td>
                  <td>
                    <span style={{ color: '#007AFF' }}>St.vin hospital</span>
                  </td>
                  <td>
                    <i className="fa fa-angle-right"></i>
                  </td>
                </tr>
              </tbody>
            </table>
            <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
              <Button variant="outlined" size="small" startIcon={<i className="fa fa-close" />}>
                clear
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                startIcon={<i className="fa fa-search" aria-hidden="true" />}
              >
                search
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h5>my List</h5>
            <Divider />
            <Stack sx={{ p: 1 }}>
              <h5>Dr. Ross, Andrew J, MD</h5>
              <p>Organization:Apolo health LLC</p>
              <p>Speciality:Obstetrics & Gynecology</p>
              <p>Phone:(222) 390-0012</p>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <i className="fa fa-phone" aria-hidden="true" />
                <i className="fa fa-comment"></i>
                <i className="fa fa-video-camera" aria-hidden="true" />
              </div>
            </Stack>
            <Divider />
            <Stack sx={{ p: 1 }}>
              <h5>Dr. Ross, Andrew J, MD</h5>
              <p>Organization:Apolo health LLC</p>
              <p>Speciality:Obstetrics & Gynecology</p>
              <p>Phone:(222) 390-0012</p>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <i className="fa fa-phone" aria-hidden="true" />
                <i className="fa fa-comment" aria-hidden="true" />
                <i className="fa fa-video-camera" aria-hidden="true" />
              </div>
            </Stack>
            <Divider />
          </Grid>
        </Grid> */}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Ai />
        {/* <img src="/static/images/aai.PNG" /> */}
        {/* <h3>Your Audits</h3>
        <h5>PATIENT DATA Screen:</h5>
        <p>
          1)The tool "EM Auditor" contains 7 screens which can be seen at top of the screenjust
          below the tool logo . To move from one screen to another, user needs to fill information
          in current screen, the presss "Save" button (Orange color) at top and bottom of screen.
          system will automatically move to next screen. in some cases, if user does not required to
          fill information in one screen he can proceeed to next by manually clicking the screen
          name from top part of the page(for example, you can click in "Medical decision Making" tab
          at top part to move to that screen directly.but remember,
          <span style={{ color: 'red' }}>
            {' '}
            if data is entered in a screen and "Save" is not clicked , that info will not be saved
            by the tools, hence system will not be able to use that data in code level calculation
          </span>
        </p>
        <br />
        <p>
          user can remove all information entered in a screen by clicking "Clear" button (blue
          color) at top and bottom of screen
        </p> */}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Box sx={{ backgroundColor: 'whitesmoke', p: 2, mb: 5 }}>
          <p style={{ color: '#3E4F7C', fontSize: '14px', fontWeight: 500 }}>Evidence</p>
        </Box>
        <Box sx={{ p: 2 }}>
          <p style={{ color: '#3E4F7C', fontSize: '14px', fontWeight: 500 }}>
            ADMISSION CERTIFICATE
          </p>
          <p style={{ fontSize: '14px', color: '#6D7278' }}>
            I certificed that i have admitted the patient as an in-patient and expect that the
            patient will require continual hospitaliztion as an in-patient in accordance with the
            Two-Midnight benchmark under. 42 CFR 412.3. I have provided the active diagnosis, plan
            of care and clinical concerns that i except will require care for Two (2) or more
            midnights with in this notes.
          </p>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="I agree with certificate"
            />
            <FormControlLabel
              control={<Checkbox />}
              label="I do not certify ( Patient is in Observation )"
            />
          </FormGroup>
          <Button variant="contained">Place Inpatient Order</Button>&nbsp;
          <Button variant="contained">Upload to EMR</Button>
        </Box>
        {/* <img src='/static/images/certifi.PNG'/> */}
      </TabPanel>
      <TabPanel value={value} index={5}>
        
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Query />
        {/* <img src="/static/images/qury.PNG" /> */}
      </TabPanel>
      <TabPanel value={value} index={7}>
        {/* <img src="/static/images/charts.PNG" /> */}
        <Chats />
        {/* <Cshell /> */}
        {/* <Ch/> */}
        {/* <p>developing...</p> */}
      </TabPanel>
      <TabPanel value={value} index={8}>
      <FhirResurceList
        
        value={getResourceByType(resources, 'Encounter')}
        //enco={enco}
        Observation={getResourceByType(resources, 'Observation')}
        CarePlan={getResourceByType(resources, 'CarePlan')}
        DiagnosticReport={getResourceByType(
          resources,
            'DiagnosticReport'
        )}
        Immunization={getResourceByType(
          resources,
            'Immunization'
        )}
        Condition={getResourceByType(resources, 'Condition')}
        EpisodeOfCare={getResourceByType(
          resources,
            'EpisodeOfCare'
        )}
        AllergyIntolerance={getResourceByType(
          resources,
            'AllergyIntolerance'
        )}
        Procedure={getResourceByType(resources, 'Procedure')}
        MedicationRequest={getResourceByType(
          resources,
            'MedicationRequest'
        )}
        MedicationDispense={getResourceByType(
          resources,
            'MedicationDispense'
        )}
   
        //resources={resources}
        />
      </TabPanel>
      <TabPanel value={value} index={10}><Orders/></TabPanel>
      <TabPanel value={value} index={11}><Documents/></TabPanel>
       
    </Box>
  );
};
export default MedicalNecessary;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
// const useStyles = makeStyles((theme: any) => ({
//   root: {
//     maxWidth: 400,
//     flexGrow: 1,
//   },
//   header: {
//     display: 'flex',
//     alignItems: 'center',
//     height: 50,
//     paddingLeft: theme.spacing(4),
//     backgroundColor: theme.palette.background.default,
//   },
//   img: {
//     height: 255,
//     display: 'block',
//     maxWidth: 400,
//     overflow: 'hidden',
//     width: '100%',
//   },
// }));
// const tutorialSteps = [
//   {
//     label: 'Review of Systems',
//     label1: 'Neurologic (Brain & Nerves)',
//     label3:
//       'problems with walking or balance, dizziness, tremor, loss of consciousness, uncontrolled motions, episodes of visual loss. ',
//   },
//   {
//     label: 'Review of Systems',
//     label1: 'Neurologic (Brain & Nerves)',
//     label3:
//       'problems with walking or balance, dizziness, tremor, loss of consciousness, uncontrolled motions, episodes of visual loss. ',
//   },
//   {
//     label: 'Review of Systems',
//     label1: 'Neurologic (Brain & Nerves)',
//     label3:
//       'problems with walking or balance, dizziness, tremor, loss of consciousness, uncontrolled motions, episodes of visual loss. ',
//   },
//   {
//     label: 'Review of Systems',
//     label1: 'Neurologic (Brain & Nerves)',
//     label3:
//       'problems with walking or balance, dizziness, tremor, loss of consciousness, uncontrolled motions, episodes of visual loss. ',
//   },
//   {
//     label: 'Review of Systems',
//     label1: 'Neurologic (Brain & Nerves)',
//     label3:
//       'problems with walking or balance, dizziness, tremor, loss of consciousness, uncontrolled motions, episodes of visual loss. ',
//   },
// ];
const data = [
  {
    code: 'AMB',
    display: 'ambulatory',
  },
  {
    code: 'EMER',
    display: 'emergency',
  },
  {
    code: 'FLD',
    display: 'field',
  },
  {
    code: 'HH',
    display: 'home health',
  },
  {
    code: 'IMP',
    display: 'inpatient encounter',
  },
  {
    code: 'ACUTE',
    display: 'inpatient acute',
  },
  {
    code: 'NONAC',
    display: 'inpatient non-acute',
  },
  {
    code: 'OBSENC',
    display: 'observation encounter',
  },
  {
    code: 'PRENC',
    display: 'pre-admission',
  },
  {
    code: 'SS',
    display: 'short stay',
  },
  {
    code: 'VR',
    display: 'virtual',
  },
];
