import React from 'react';
import ReactDOM from 'react-dom';
import FHIR from 'fhirclient';

import App from './components/App.tsx';
import './styles/index.scss';
import './utils/fontawesomeLibrary';

const rootElement = document.getElementById('root');

const smartLaunch = () => {
  // Authorize application
  FHIR.oauth2
    .init({
      //clientId: '96391857-5b19-4734-8050-654c4796a209',
      clientId:'ca6901f5-7fc1-4cc2-b74e-ac43820cef31',
      //scope:'launch launch/patient patient/read offline_access'
      scope: 'patient/Patient.read patient/*.read openid profile patient/Observation.read user/*.read launch patient/*.write fhirUser patient/Observation.write'
    })
    .then(client => {
      console.log(client)
      ReactDOM.render(<App client={client} />, rootElement);
    });
};

 smartLaunch();
// iss:
// "https://launch.smarthealthit.org/v/r3/sim/eyJoIjoiMSIsImIiOiJzbWFydC0xNjQyMDY4IiwiZSI6InNtYXJ0LVByYWN0aXRpb25lci03MTYxNDUwMiJ9/fhir",
// redirectUri: "test.html",
// clientId: "whatever",
// scope: "launch/patient offline_access openid fhirUser",