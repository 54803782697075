import React, { FC, useState, useEffect } from 'react';

//import Header from 'components/Header';
import Navigation from 'components/Navigation';

//import logo from '../logo.svg';
import { getPatientRecord } from '../utils/fhirExtract';
import { FHIRClientProvider } from './FHIRClient';
import { PatientProvider } from './PatientProvider';
import PatientRecord from './PatientRecord/PatientRecord';
import config from 'utils/ConfigManager';
import { PractitionerProvider } from './practitionerProvider';
import MdicalNecsaary from './MedicalNecessary/MedicalNecessary';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme/index';
import { Container } from '@mui/material';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

//import './index.scss';store/sagas
import rootSaga from '../store/sagas';
import rootReducer from '../store/reducers';
import ScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { PatientFirebase } from './PatientSnapshot/patientfirebase';
import { EncounterProvider } from './Navigation/encounterporvider';
const sagaMiddleware = createSagaMiddleware();
// if (
//   process.env.NODE_ENV !== "production" &&
//   process.browser &&
//   window.__REDUX_DEVTOOLS_EXTENSION__
// ) {
//   devtools = window.__REDUX_DEVTOOLS_EXTENSION__();
// }
//const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose;// typeof window === 'object' &&
// window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//     // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//   }) : compose;

const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);
interface AppProps {
  client: any; // TODO: fhirclient.Client
}

const App: FC<AppProps> = ({ client }) => {
  const [patientRecords, setPatientRecords] = useState<Array<any>>([]);

  useEffect(() => {
    getPatientRecord(client).then((records: Array<any>) => {
      setPatientRecords(records);
    });
  }, [client]);

  return (
    <div className="exa">
      <ScrollBar component="div">
        <div className="cont">
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <FHIRClientProvider client={client}>
                {/* <Container> */}
                <PatientFirebase>
                  <EncounterProvider>
                    <PatientProvider>
                      <PractitionerProvider>
                        <div>
                          {/* <Header logo={logo} title={config.get('appName', 'SMART App')} /> */}
                          <Navigation />
                        </div>
                        {/* <div>{`Fetched ${patientRecords.length} resources`}</div> */}
                        <MdicalNecsaary resources={patientRecords}/>
                        <PatientRecord resources={patientRecords} />
                      </PractitionerProvider>
                    </PatientProvider>
                  </EncounterProvider>
                </PatientFirebase>
                {/* </Container> */}
              </FHIRClientProvider>
            </ThemeProvider>
          </Provider>
        </div>
      </ScrollBar>
    </div>
  );
};

export default App;
