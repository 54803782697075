import React, { useState, useEffect } from 'react';
import { firebas as firebase } from '../MedicalNecessary/firebase';
//import { useCollectionData } from 'react-firebase-hooks/firestore'
export const patContext = React.createContext();
const firestore = firebase.firestore();
export const PatientFirebase = ({ children }) => {
  const [patients, setpatients] = React.useState();
  // React.useEffect(() => {
  //   firestore
  //     .collection('patient')
  //     .get()
  //     .then((data) => {
  //     // console.log(data);
  //       let pdata = [];
  //       data.forEach((doc) => {
  //         pdata.push(doc.data());
  //       });
  //       return setpatients(pdata);
  //     })
  //     .catch((err) => console.error(err));
  // }, []);
  useEffect(() => {
    firestore
      .collection('patient')
      .get()
      .then((response) => {
        const fetchedpatient = [];
        response.docs.forEach((document) => {
          const fetchedpat = {
            id: document.id,
            ...document.data(),
          };
          fetchedpatient.push(fetchedpat);
        });

        patdetails(fetchedpatient);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const patdetails = (res) => {
    const fetcheddata = [];
    Promise.all(
      res &&
      res.map((id) => {
          return new Promise((resolve) => {
            firestore
              .collection('patient')
              .doc(id.id)
              .collection('inbox')
              .get()
              .then((response) => {
                return new Promise(() => {
                  response.forEach((document) => {
                    const fetchedpat = {
                      //id: document.id,
                      ...document.data(),
                    };
                    fetcheddata.push(fetchedpat);
                    resolve();
                  });
                });
              });
          });
        })
    ).then(() => {
      setpatients(fetcheddata)
      ///console.log(fetcheddata);
    });
  };
// const [en,seten]=React.useState()
//   const patdetailss = (res) => {
//     const fetcheddatas = [];
//     Promise.all(
//       res &&
//       res.map((id) => {
//           return new Promise((resolve) => {
//             firestore
//               .collection('patient')
//               .doc(id.id)
//               .collection('encounter')
//               .get()
//               .then((response) => {
//                 return new Promise(() => {
//                   response.forEach((document) => {
//                     const fetchedpat = {
//                       //id: document.id,
//                       ...document.data(),
//                     };
//                     fetcheddatas.push(fetchedpat);
//                     resolve();
//                   });
//                 });
//               });
//           });
//         })
//     ).then(() => {
//      // setpatients(fetcheddata)
//      seten(fetcheddatas)
//       //console.log(fetcheddatas);
//     });
//   };
  
  let data =
    patients && patients.map((res) => (res.data !== undefined ? JSON.parse(res.data) : null));
    // let a=en &&en.map((res)=>(res.data !== undefined ? JSON.parse(res.data) : null))
    // console.log(a)
  return <patContext.Provider value={data}>{children}</patContext.Provider>;
};
