
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
    Tabs,
    Tab,
    Box,
    Divider,
    TextareaAutosize,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Grid,
    List,
    ListItem,
    ListItemText,
    DialogActions,
    InputAdornment,
    Icon,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    FormGroup,
    FormLabel,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,Menu,MobileStepper,Paper,Typography,Button
} from '@mui/material'

//import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
//import db from '../../../auth/firebaseconfig'
import { usePatient } from '../PatientProvider';
import { firebas as firebase } from '../MedicalNecessary/firebase';
//import { firebas as firebase } from '../MedicalNecessary/firebase';
//import { xml2json, json2xml } from 'xml-js'
//import Autocomplete from '@material-ui/lab/Autocomplete'
//import { makeStyles, useTheme } from '@material-ui/core/styles'
import { styled,useTheme } from '@mui/system'
import {KeyboardArrowLeft,KeyboardArrowRight} from '@mui/icons-material'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import samplePDF from './sample.pdf'
import ReactJson from 'react-json-view'
//import Reviewserach from './reviewsearch'
import { storage } from '../MedicalNecessary/firebase'
import axios from 'axios'
import beautify from 'xml-beautifier'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
//import { ResizableBox } from 'react-resizable'
//import './observation.scss'
import Splitter from '@devbookhq/splitter'
//import  lforms from 'lforms'
const ReviewsTab = (props) => {
    const auth = firebase.auth()
const firestore = firebase.firestore()
    const [value, setValue] = React.useState(0)
    const theme = useTheme()
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const [activeStep, setActiveStep] = React.useState(0)
    const maxSteps = tutorialSteps.length

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
    const [leftclose, setleftclose] = React.useState(true)
    const handleleft = () => {
        setleftclose(!leftclose)
    }

    ///show pdf code
    const [pdf, setPdf] = React.useState(samplePDF)
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1) //setting 1 to show fisrt page

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages)
        setPageNumber(1)
    }
    function changePage(offset) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset)
    }
    function previousPage() {
        changePage(-1)
    }
    function nextPage() {
        changePage(1)
    }
    const [open, setOpen] = React.useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    const [image, setImage] = useState(null)
    const onImageChange = (e) => {
        //  console.log(e)
        const reader = new FileReader()

        ///extra
        // reader.readAsDataURL(file)
        // reader.onload = () => {
        //     setPdf(reader.result)
        // }
        //
        let file = e.target.files[0] // get the supplied file
        // if there is a file, set image to that file
        if (file) {
            reader.onload = () => {
                if (reader.readyState === 2) {
                    //  console.log(file)
                    setImage(file)
                    setPdf(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0])
            // if there is no file, set image back to null
        } else {
            setImage(null)
        }
    }
    const uploadToFirebase = () => {
        //1.
        //console.log(image)
        if (image) {
            //2.

            const storageRef = storage.ref('/doc_pdf/')
            //3.
            const imageRef = storageRef.child(image.name)
            //4.
            imageRef
                .put(image)
                //5.
                .then(() => {
                    alert('Image uploaded successfully to Firebase.')
                })
        } else {
            ///alert('Please upload an image first.')
        }
    }

    ///
    ///lforms
    const [formResult, setFormResult] = useState(null)
    useEffect(() => {
        listItem()
        //lforms.Util.addFormToPage(formDef, 'ontainer')
    }, [])
    // const handleSubmit = () => {
    //     let validate = lforms.Util.checkValidity()

    //     if (validate !== null) {
    //         alert('erros ' + JSON.stringify(validate))
    //     } else {
    //         var qr = lforms.Util.getFormData()
    //         console.log(qr)
    //         setFormResult(qr)
    //     }
    // }
    const [im, setim] = useState(null)
    React.useEffect(() => {
        // const storageRefs = storage.ref()
        // storageRefs
        //     .child('doc_pdf/composition-example.json')
        //     .getDownloadURL()
        //     .then(function (url) {
        //         axios.get(url).then((response) => {
        //             setim(response.data)
        //         })
        //     })
        //     .catch(function (error) {
        //         console.log(error)
        //     })
        doclistpatient()
    }, [])
    /////////////////

    // States for data and image
    const [data, setData] = useState([])
    const [images, setImages] = useState('')

    const upload = () => {
        if (images == null) return
        // Sending File to Firebase Storage
        storage
            .ref(`/doc_pdf/${images.name}`)
            .put(images)
            .on('state_changed', alert('success'), alert)
    }

    // List All Files
    const listItem = () => {
        setData([])
        storage
            .ref()
            .child('doc_pdf/')
            .listAll()
            .then((res) => {
                res.items.forEach((item) => {
                    setData((arr) => [...arr, item.name])
                })
            })
            .catch((err) => {
                alert(err.message)
            })
        // setleftclose(!leftclose)
    }
    const [viewpatdoc, setviewpatdoc] = React.useState()
    const doclistpatient = () => {
        firestore
        .collection(`patient/${patient.id}/DocReference`)
            .get()
            .then((data) => {
                //console.log(data)
                let ddata = []
                data.forEach((doc) => {
                    if (doc.data().data !== '') {
                        ddata.push(doc.data())
                    }
                })

                return setviewpatdoc(ddata)
            })
            .catch((err) => console.error(err))
    }

    /////testing
    const doclistpatients = (rr) => {
        // console.log(rr)
        firestore.collection(`patient/${patient.id}/DocReference`)
            .doc(rr)
            .get()
            .then((snapshot) => console.log(snapshot.data()))

            // .then((data) => {
            //     console.log(data);
            //     let ddata = []
            //     data.forEach((doc) => {
            //         if (doc.data().data !== '') {
            //             ddata.push(doc.data())
            //         }
            //     })

            //     return console.log(ddata)
            // })
            .catch((err) => console.error(err))
    }
    ////end of testing
    const [values, setValues] = React.useState()
    const [inputValue, setInputValue] = React.useState('')
    // const onch = (event, newInputValue) => {
    //     //const { name, value } = event.target
    //     setInputValue(newInputValue)
    //     // serumreview({ ...umreview, [name]: value })
    // }
    const input = im

    // const xml = json2xml(input, {
    //     compact: true,
    // })
    ////checbox
    const [valued, setValued] = React.useState('')
    const handleChanged = (event) => {
        setValued(event.target.value)
    }
    const [selectedjson, setselectedjson] = React.useState()
    //selected json file
    var re = /(?:\.([^.]+))?$/
    const handselections = (rr) => {
        //console.log(rr)
        if (rr) {
            //doclistpatients(rr)
            setim(null)
            setdocname(rr)
            let a = re.exec(rr)

            setValued(a[1])
            const storageRefs = storage.ref()
            storageRefs
                .child(`doc_pdf/${rr}`)
                .getDownloadURL()
                .then(function (url) {
                    // console.log(url)
                    // adddocref(url, res)
                    axios.get(url).then((response) => {
                        //console.log(response.data)

                        setim(response.data)
                    })
                })
                .catch(function (error) {
                    console.log(error)
                })
            //console.log(res)

            setAnchorEls(null)
            setleftclose(!leftclose)
        }
    }
    const [docname, setdocname] = React.useState('')
    const handselection = (res) => {
        //console.log(res)
        if (res) {
            setim(null)
            setdocname(res[1])
            const storageRefs = storage.ref()
            storageRefs
                .child(`doc_pdf/${res[1]}`)
                .getDownloadURL()
                .then(function (url) {
                    // console.log(url)
                    //adddocref(url, res)
                    axios.get(url).then((response) => {
                        setim(response.data)
                    })
                })
                .catch(function (error) {
                    console.log(error)
                })
            //console.log(res)
            setleftclose(!leftclose)
        }
    }
    const adddocref = (uu, res) => {
        // console.log('dsfsdgsa' ,res)
        var nowDate = new Date()
        var date =
            nowDate.getFullYear() +
            '/' +
            (nowDate.getMonth() + 1) +
            '/' +
            nowDate.getDate()
            firestore.collection(`patient/${patient.id}/DocReference`)
            .doc(res.data)
            .set({
                Name: res.Name,
                data: res.data,
                type: res.type,
                Date: date,
                // patient: localStorage.getItem('patient'),
            })
    }
    //const xmls = beautify(xml)
    //searchbar
    const [search, setSearch] = useState('')
    const [filter, setFilter] = useState([])
    useEffect(() => {
        setFilter(
            data.filter((res) =>
                res.toLowerCase().includes(search.toLowerCase())
            )
        )
    }, [search, data])
    const [advs, setadvs] = React.useState(false)
    const handleadv = () => {
        setadvs(!advs)
    }
    ///upoad dialog
    const patient = usePatient();
    console.log(JSON.stringify(patient) + 'ffff')
    const finit = {
        name: '',
        type: '',
        Format: '',
        patient:
        patient &&
        patient.name[0] &&
        patient.name[0].given[0],
    }
    const [ufile, setupload] = React.useState(finit)
    const Handleff = (e) => {
        const { name, value } = e.target
        setupload({ ...ufile, [name]: value })
    }
    const handedocref = (e) => {
        e.preventDefault()
        // console.log(ufile)
        if (image) {
            //console.log(db)
            //  console.log(ufile)
            uploadToFirebase()
            firestore.collection(
                `patient/${patient.id}/DocReference`
            )
                .doc(image.name)
                .set({
                    Name: ufile.name,
                    type: ufile.type,
                    data: image.name,
                    Date: new Date(),
                    patient: ufile.patient,

                    // patient: localStorage.getItem('patient'),
                })
            setupload(finit)
        }
    }

    //custom toolbar
    const butt = () => (
        <>
            {/* <IconButton onClick={handleadv}>
                <Icon>info_outlined</Icon>
            </IconButton> */}
            {/* <IconButton onClick={handleClicks}>
                <Icon>pageview</Icon>
            </IconButton> */}

            <IconButton onClick={handleClickOpens}>
                <Icon>date_range</Icon>
            </IconButton>
            <IconButton onClick={handleClickOpen}>
                <Icon>backup</Icon>
            </IconButton>
        </>
    )

    const options = {
        //filter: false,
        // filterType: 'checkbox',
        selectableRows: 'none',
        viewColumns: false,
        download: false,
        print: false,
        //search: false,
        //selectableRows: 'none',
        customToolbar: () => butt(),

        onRowClick: (rowData, rowState) => {
            //console.log(rowData, rowState);
            handselection(rowData)
            setValued(re.exec(rowData[1])[1])
            // props.history.push('/um-patient')
        },
        // onClick={() => handselection(res)}
    }

    ///popupmenu
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const [val, setval] = React.useState(0)
    const handleClosess = (val) => {
        // console.log(val)
        setval(val)
        setAnchorEl(null)
    }

    //notes
    const [notes, setnotes] = useState('')
    const notessubmit = (event) => {
        event.preventDefault()
        if (!notes) return
        //console.log(notes)

        firestore.collection(
            `um_case_reviews/${patient.id}/Notes`
        )
            .doc()
            .set({
                Notes: notes,
                Date: new Date(),
            })
        //.then((snapshot) => console.log(snapshot.data()))
        setnotes('')
    }

    //date range calculator popup
    const initialdate = {
        startdate: '',
        enddate: '',
    }
    const [drange, setdrange] = React.useState(initialdate)
    const drangee = (e) => {
        const { name, value } = e.target
        setdrange({ ...drange, [name]: value })
    }
    const [opens, setOpens] = React.useState(false)
    const handleClickOpens = () => {
        setOpens(true)
    }
    const handleCloses = () => {
        setOpens(false)
    }
    const daterangesubmits = (e) => {
        e.preventDefault()
        var startDate = moment(new Date(drange.startdate)).format('DD-MM-YYYY')
        var endDate = moment(new Date(drange.enddate)).format('DD-MM-YYYY')
        //new Date(drange.enddate)

        var resultProductData = viewpatdoc.filter((a) => {
            var date = moment(new Date(a.Date.seconds * 1000)).format(
                'DD-MM-YYYY'
            )
            return date >= startDate && date <= endDate
        })
        setviewpatdoc(resultProductData)
        setdrange(initialdate)
        ///
    }
    //adv search
    const [anchorEls, setAnchorEls] = React.useState(null)
    const handleClicks = (event) => {
        setAnchorEls(event.currentTarget)
    }
    const handleClo = () => {
        setAnchorEls(null)
    }
    ///adv search fields
    const advinit = {
        selecteds: '',
        resourceid: '',
        rangestart: '',
        rangeend: '',
        matches: '',
        code: '',
        sysyem: '',
        selectsyscod: '',
        matselect: '',
        rangestartsel: 'eq',
        rangeendsel: 'eq',
    }
    const [serviceList, setServiceList] = useState([advinit])
    const handleServiceChange = (e, index) => {
        const { name, value } = e.target
        const list = [...serviceList]
        list[index][name] = value
        setServiceList(list)
    }

    const handleServiceRemove = (index) => {
        const list = [...serviceList]
        list.splice(index, 1)
        setServiceList(list)
    }
    const handleServiceAdd = () => {
        setServiceList([...serviceList, advinit])
    }
    // const [anchorEladv, setAnchorEladv] = React.useState(null)
    // const handleClickadv = (event) => {
    //     setAnchorEladv(event.currentTarget)
    // }
    // const handleCloseadv = (val) => {
    //     setAnchorEladv(null)
    // }
    const [openadv, setOpenadv] = React.useState(false)
    const handleClickOpenadv = () => {
        setOpenadv(true)
    }
    const handleCloseadv = () => {
        setOpenadv(false)
    }
    // function updateQueryStringParameter(uri, key, value) {
    //     var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    //     var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    //     if (uri.match(re)) {
    //       return uri.replace(re, '$1' + key + "=" + value + '$2');
    //     }
    //     else {
    //       return uri + separator + key + "=" + value;
    //     }
    //   }
    const advsearchapi = () => {
        //  console.log(serviceList)
        let rrs = serviceList.map((res) =>
            res.rangestart !== ''
                ? `date=${res.rangestartsel}${res.rangestart}&date=${res.rangeendsel}${res.rangeend}`
                : ''
        )
        let subid = serviceList.map((res) =>
            res.resourceid !== '' ? `&subject=${res.resourceid}` : ''
        )
        // let str=str.replace(",","")
        let url = `http://hapi.fhir.org/baseR4/DocumentReference?${rrs}${subid}$&_pretty=true`
        let aa = url.replace(',', '')
        //console.log(aa)

        let p = new URLSearchParams()
        p.set('foo', 'bar')
        p.set('name', 'Jack & Jill?')
        //console.log('http://example.com/?' + p.toString())
        axios
            .get(aa)
            .then(function (response) {
                //console.log(response)
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    
    return (
        <div>
            {/* {console.log(serviceList)} */}
            <Menu
                id="simple-menu"
                anchorEl={anchorEls}
                keepMounted
                style={{ width: '1000%', marginTop: '35px', padding: '5px' }}
                open={Boolean(anchorEls)}
                onClose={handleClo}
            >
                {/* <Autocomplete
                    id="free-solo-demo"
                    //freeSolo
                    style={{ width: 400 }}
                    value={values || ''}
                    onChange={(event, newValue) => {
                        setValues(newValue)
                        handselections(newValue)
                    }}
                    name="values"
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue)
                    }}
                    options={data.map((option) => option)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size="small"
                            label="search"
                            margin="normal"
                        />
                    )}
                /> */}
            </Menu>
            <Grid container spacing={1} style={{ backgroundColor: 'white' }}>
                <Grid item xs={12} sm={12}>
                    {leftclose ? (
                        <div>
                            <IconButton onClick={handleClickOpenadv}>
                                <Icon>search</Icon>
                            </IconButton>
                            <Dialog
                                open={openadv}
                                onClose={handleCloseadv}
                                aria-labelledby="form-dialog-title"
                                maxWidth="md"
                            >
                                <DialogTitle id="form-dialog-title">
                                    Advance Search
                                </DialogTitle>
                                <DialogContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                type="search"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={handleServiceAdd}
                                            >
                                                +
                                            </Button>

                                            {/* <Button variant='outlined' size='small'>+</Button> */}
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            {serviceList.map(
                                                (singleService, index) => (
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        key={index}
                                                    >
                                                        {/* {console.log(
                                                            singleService.selecteds
                                                        )} */}
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            select
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={
                                                                singleService.selecteds
                                                            }
                                                            name="selecteds"
                                                            onChange={(e) =>
                                                                handleServiceChange(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            label="select"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            {advfilter.map(
                                                                (
                                                                    res,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            index
                                                                        }
                                                                        value={
                                                                            res
                                                                        }
                                                                    >
                                                                        {
                                                                            res.val
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>
                                                )
                                            )}
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <form
                                                className="App"
                                                autoComplete="off"
                                            >
                                                <div className="form-field">
                                                    <label htmlFor="service">
                                                        {/* Service(s) */}
                                                    </label>
                                                    {serviceList.map(
                                                        (
                                                            singleService,
                                                            index
                                                        ) => (
                                                            <div key={index}>
                                                                <div>
                                                                    <Grid
                                                                        container
                                                                        spacing={
                                                                            0
                                                                        }
                                                                        alignItems="flex-end"
                                                                    >
                                                                        <Grid
                                                                            item
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                1 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                3 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                11 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                18 ? (
                                                                                <div>
                                                                                    <select
                                                                                        value={
                                                                                            singleService.rangestartsel
                                                                                        }
                                                                                        name="rangestartsel"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="eq">
                                                                                            eq
                                                                                        </option>
                                                                                        <option value="gt">
                                                                                            gt
                                                                                        </option>
                                                                                        <option value="ge">
                                                                                            ge
                                                                                        </option>
                                                                                        <option value="lt">
                                                                                            lt
                                                                                        </option>
                                                                                        <option value="le">
                                                                                            le
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                10
                                                                            }
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                1 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                3 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                10 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                18 ? (
                                                                                <div>
                                                                                    <TextField
                                                                                        id="input-with-icon-grid"
                                                                                        label=""
                                                                                        type="date"
                                                                                        fullWidth
                                                                                        value={
                                                                                            singleService.rangestart
                                                                                        }
                                                                                        name="rangestart"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                        <br />
                                                                        <Grid
                                                                            item
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                1 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                3 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                10 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                18 ? (
                                                                                <div>
                                                                                    <select
                                                                                        value={
                                                                                            singleService.rangeendsel
                                                                                        }
                                                                                        name="rangeendsel"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="eq">
                                                                                            eq
                                                                                        </option>
                                                                                        <option value="gt">
                                                                                            gt
                                                                                        </option>
                                                                                        <option value="ge">
                                                                                            ge
                                                                                        </option>
                                                                                        <option value="lt">
                                                                                            lt
                                                                                        </option>
                                                                                        <option value="le">
                                                                                            le
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                10
                                                                            }
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                1 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                3 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                10 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                18 ? (
                                                                                <div>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        id="input-with-icon-grid"
                                                                                        label=""
                                                                                        type="date"
                                                                                        value={
                                                                                            singleService.rangeend
                                                                                        }
                                                                                        name="rangeend"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                4 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                21 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                25 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                26 ? (
                                                                                <div>
                                                                                    {' '}
                                                                                    <select
                                                                                        value={
                                                                                            singleService.matselect
                                                                                        }
                                                                                        name="matselect"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="Matches">
                                                                                            Matches
                                                                                        </option>
                                                                                        <option value="Exactly">
                                                                                            Exactly
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            {/* <Icon>search</Icon> */}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                8
                                                                            }
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                4 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                21 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                25 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                26 ? (
                                                                                <div>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label=""
                                                                                        value={
                                                                                            singleService.matches
                                                                                        }
                                                                                        name="matches"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                        <br />
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                4
                                                                            }
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                5 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                6 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                7 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                10 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                13 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                14 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                20 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                24 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                27 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                29 ? (
                                                                                <div>
                                                                                    <select
                                                                                        id="selectsyscod"
                                                                                        value={
                                                                                            singleService.selectsyscod
                                                                                        }
                                                                                        name="selectsyscod"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <option value="=">
                                                                                            =
                                                                                        </option>
                                                                                        <option value=":text">
                                                                                            :text
                                                                                        </option>
                                                                                        <option value=":not">
                                                                                            :not
                                                                                        </option>
                                                                                        <option value=":above">
                                                                                            :above
                                                                                        </option>
                                                                                        <option value=":text">
                                                                                            :text
                                                                                        </option>
                                                                                        <option value=":below">
                                                                                            :below
                                                                                        </option>
                                                                                        <option value=":in">
                                                                                            :in
                                                                                        </option>
                                                                                        <option value=":not-in">
                                                                                            :not-in
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                4
                                                                            }
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                5 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                6 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                7 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                10 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                13 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                14 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                20 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                24 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                27 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                29 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                30 ? (
                                                                                <div>
                                                                                    <TextField
                                                                                        //fullWidth
                                                                                        value={
                                                                                            singleService.sysyem
                                                                                        }
                                                                                        name="sysyem"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        label="system"
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                4
                                                                            }
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                5 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                6 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                7 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                10 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                13 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                14 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                20 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                24 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                27 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                29 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                30 ? (
                                                                                <div>
                                                                                    {' '}
                                                                                    <TextField
                                                                                        // fullWidth
                                                                                        label="code"
                                                                                        value={
                                                                                            singleService.code
                                                                                        }
                                                                                        name="code"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                            sm={
                                                                                12
                                                                            }
                                                                        >
                                                                            {singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                2 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                9 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                11 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                12 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                15 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                16 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                17 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                28 ||
                                                                            singleService
                                                                                .selecteds
                                                                                .id ==
                                                                                30 ? (
                                                                                <div>
                                                                                    <TextField
                                                                                        fullWidth
                                                                                        label="Resource id"
                                                                                        value={
                                                                                            singleService.resourceid
                                                                                        }
                                                                                        name="resourceid"
                                                                                        size="small"
                                                                                        onChange={(
                                                                                            e
                                                                                        ) =>
                                                                                            handleServiceChange(
                                                                                                e,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Grid>
                                                                    </Grid>
                                                                </div>
                                                                {/* <div >
                                                            {serviceList.length !==
                                                                1 && (
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        handleServiceRemove(
                                                                            index
                                                                        )
                                                                    }
                                                                   
                                                                >
                                                                    <span>
                                                                        x
                                                                    </span>
                                                                </button>
                                                            )}
                                                        </div> */}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                {/* <div className="output">
        <h2>Output</h2>
        {serviceList &&
          serviceList.map((singleService, index) => (
            <ul key={index}>
              {singleService.service && <li>{singleService.service}</li>}
            </ul>
          ))}
      </div> */}
                                            </form>
                                        </Grid>
                                    </Grid>

                                    <h5>
                                        Includes Also include resources which
                                        are referenced by the search results
                                    </h5>

                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    //checked={state.checkedA} onChange={handleChange}
                                                    name="checkedA"
                                                />
                                            }
                                            label="DocumentReference:authenticator"
                                            color="primary"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    //checked={state.checkedA} onChange={handleChange}
                                                    name="checkedA"
                                                />
                                            }
                                            label="DocumentReference:author"
                                            color="primary"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    //checked={state.checkedA} onChange={handleChange}
                                                    name="checkedA"
                                                />
                                            }
                                            label="DocumentReference:custodian"
                                            color="primary"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    //checked={state.checkedA} onChange={handleChange}
                                                    name="checkedA"
                                                />
                                            }
                                            label="DocumentReference:encounter"
                                            color="primary"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    //checked={state.checkedA} onChange={handleChange}
                                                    name="checkedA"
                                                />
                                            }
                                            label="DocumentReference:related"
                                            color="primary"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    //checked={state.checkedA} onChange={handleChange}
                                                    name="checkedA"
                                                />
                                            }
                                            label="DocumentReference:patient"
                                            color="primary"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    //checked={state.checkedA} onChange={handleChange}
                                                    name="checkedA"
                                                />
                                            }
                                            label="DocumentReference:relatesto"
                                            color="primary"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    //checked={state.checkedA} onChange={handleChange}
                                                    name="checkedA"
                                                />
                                            }
                                            label="DocumentReference:subject"
                                            color="primary"
                                        />
                                    </FormGroup>
                                    <DialogActions>
                                        <Button
                                            onClick={handleCloseadv}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={advsearchapi}
                                        >
                                            search
                                        </Button>
                                    </DialogActions>
                                </DialogContent>
                            </Dialog>
                            <MUIDataTable
                                title={'List of Documents'}
                                data={viewpatdoc}
                                columns={columns}
                                options={options}
                            />
                            <Dialog
                                maxWidth="sm"
                                open={opens}
                                onClose={handleCloses}
                                aria-labelledby="form-dialog-title"
                            >
                                <DialogTitle id="form-dialog-title">
                                    select Date Range
                                </DialogTitle>

                                <form onSubmit={daterangesubmits}>
                                    <DialogContent>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            label="Start Date"
                                            name="startdate"
                                            value={drange.startdate}
                                            onChange={drangee}
                                            fullWidth
                                            type="date"
                                            required
                                            placeholder="start date"
                                            size="small"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            label="End Date"
                                            name="enddate"
                                            value={drange.enddate}
                                            onChange={drangee}
                                            type="date"
                                            fullWidth
                                            required
                                            placeholder="End Date"
                                            size="small"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={handleCloses}
                                            color="primary"
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit" color="primary">
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </form>
                            </Dialog>
                            {/* <Grid container spacing={1}>
                                <Grid item xs={12} sm={9}>
                                    <Autocomplete
                                        id="free-solo-demo"
                                        //freeSolo
                                        value={values || ''}
                                        onChange={(event, newValue) => {
                                            setValues(newValue)
                                            handselections(newValue)
                                        }}
                                        name="values"
                                        inputValue={inputValue}
                                        onInputChange={(
                                            event,
                                            newInputValue
                                        ) => {
                                            setInputValue(newInputValue)
                                        }}
                                        options={data.map((option) => option)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                label="search"
                                                margin="normal"
                                               
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div style={{ marginTop: '32px' }}>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            onClick={handleadv}
                                        >
                                            i
                                        </Button>
                                        <IconButton onClick={handleadv}>
                                            <Icon>info_outlined</Icon>
                                        </IconButton>
                                        <IconButton onClick={handleClickOpen}>
                                            <Icon>
                                                backup
                                            </Icon>
                                        </IconButton>
                                    </div>
                                </Grid>
                            </Grid> */}
                            <div>
                                <Dialog
                                    maxWidth="sm"
                                    open={open}
                                    onClose={handleClose}
                                    aria-labelledby="form-dialog-title"
                                >
                                    <DialogTitle id="form-dialog-title">
                                        Upload
                                    </DialogTitle>

                                    <form onSubmit={handedocref}>
                                        <DialogContent>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="patient"
                                                label="patient"
                                                name="patient"
                                                value={ufile.patient}
                                                onChange={Handleff}
                                                fullWidth
                                                required
                                                placeholder="patient name"
                                                size="small"
                                                variant="outlined"
                                            />
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="Author"
                                                name="name"
                                                value={ufile.name}
                                                onChange={Handleff}
                                                fullWidth
                                                required
                                                placeholder="Author name"
                                                size="small"
                                                variant="outlined"
                                            />

                                            <FormControl
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                fullWidth
                                            >
                                                <InputLabel id="type">
                                                    type
                                                </InputLabel>
                                                <Select
                                                    labelId="type"
                                                    id="type"
                                                    name="type"
                                                    value={ufile.type}
                                                    onChange={Handleff}
                                                    label="type"
                                                    placeholder="Document types"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {doctype.map(
                                                        (res, index) => (
                                                            <MenuItem
                                                                key={index}
                                                                value={
                                                                    res.value
                                                                }
                                                            >
                                                                {res.value}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>

                                            <FormControl
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                fullWidth
                                            >
                                                <InputLabel id="Format">
                                                    Format
                                                </InputLabel>
                                                <Select
                                                    labelId="Format"
                                                    id="Format"
                                                    name="Format"
                                                    value={ufile.Format}
                                                    onChange={Handleff}
                                                    label="Format"
                                                >
                                                    <MenuItem value="">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    <MenuItem value={'CCDA'}>
                                                        CCDA
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'FHIR Bundle'}
                                                    >
                                                        FHIR Bundle
                                                    </MenuItem>
                                                    <MenuItem value={'PDF'}>
                                                        PDF
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={'Text file'}
                                                    >
                                                        Text file
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            <input
                                                type="file"
                                                id="contained-button-file"
                                                //accept="application/pdf"
                                                // accept=".json"
                                                accept={
                                                    finit.Format == 'CCDA'
                                                        ? 'text/xml'
                                                        : finit.Format ==
                                                          'FHIR Bundle'
                                                        ? '.json'
                                                        : finit.Format == 'PDF'
                                                        ? 'application/pdf'
                                                        : 'text/xml'
                                                }
                                                //accept="text/xml"
                                                style={{ display: 'none' }}
                                                onChange={(e) => {
                                                    onImageChange(e)
                                                }}
                                            />
                                            {/* {console.log(finit)} */}
                                            <label htmlFor="contained-button-file">
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    color="primary"
                                                    // disabled={
                                                    //     (finit.Format = ''
                                                    //         ? false
                                                    //         : true)
                                                    // }
                                                    //onClick={uploadToFirebase}
                                                    //color="primary"
                                                    startIcon={
                                                        <Icon>attach_file</Icon>
                                                    }
                                                >
                                                    Choose a file...*
                                                </Button>
                                            </label>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={handleClose}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                type="submit"
                                                color="primary"
                                            >
                                                Submit
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </Dialog>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {/* <TextField
                                type="search"
                                placeholder="search by Name"
                                fullWidth
                                margin='dense'
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Icon>search</Icon>
                                        </InputAdornment>
                                    ),
                                }}
                            /> */}

                    {/* {advs ? (
                            <div>
                                <h6
                                    style={{
                                        backgroundColor: '#F3F6F9',
                                        padding: '10px',
                                    }}
                                >
                                    Advance Search
                                    <Button
                                        style={{
                                            float: 'right',
                                            marginTop: '-7px',
                                        }}
                                        size="small"
                                        variant="outlined"
                                        //onClick={handleleft}
                                    >
                                        search tips
                                    </Button>
                                </h6>

                                <TextareaAutosize
                                    aria-label="minimum height"
                                    minRows={3}
                                    placeholder="history and physical"
                                    style={{ width: '100%' }}
                                />
                                <TextField
                                    label="date"
                                    fullWidth
                                    variant="outlined"
                                    type="date"
                                    margin="dense"
                                />
                                <TextField
                                    label="date"
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    type="date"
                                />
                                <h6
                                    style={{
                                        backgroundColor: '#F3F6F9',
                                        padding: '10px',
                                    }}
                                >
                                    Tags
                                    <Button
                                        style={{
                                            float: 'right',
                                            marginTop: '-7px',
                                        }}
                                        size="small"
                                        variant="outlined"
                                    >
                                        search all
                                    </Button>
                                </h6>

                                <Button color="primary" size="small">
                                    EOB
                                </Button>
                                <Button color="primary" size="small">
                                    HPR
                                </Button>
                                <Button color="primary" size="small">
                                    MR
                                </Button>
                                <Button color="primary" size="small">
                                    OPR
                                </Button>
                                <Button color="primary" size="small">
                                    UB
                                </Button>

                                <DialogActions>
                                    <Button color="primary" size="small">
                                        Cancel
                                    </Button>
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                    >
                                        search
                                    </Button>
                                </DialogActions>
                                <h6
                                    style={{
                                        backgroundColor: '#F3F6F9',
                                        padding: '10px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Results
                                </h6>
                            </div>
                        ) : (
                            ''
                        )} */}

                    {/* {viewpatdoc &&
                            viewpatdoc.map((res, index) => (
                                <List
                                    key={index}
                                    component="nav"
                                    aria-label="secondary mailbox folders"
                                    style={{ border: '1px solid lightgrey' }}
                                    dense
                                >
                                    <ListItem
                                        button
                                        onClick={() => handselection(res)}
                                        style={{ height: '20px' }}
                                    >
                                        <ListItemText
                                            primary={
                                                <span>
                                                    <b>{res.data}</b>
                                                </span>
                                            }
                                        />
                                    </ListItem>
                                </List>
                            ))} */}
                </Grid>

                <Grid item xs={12} sm={12}>
                    {!leftclose ? (
                        <div className="Apps">
                            <div className="splits">
                                <Splitter>
                                    <div className="tile">
                                        <header
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-evenly',
                                                marginTop: '15px',
                                            }}
                                        >
                                           
                                            {/* <Icon>arrow_left</Icon>
                                            <Icon>arrow_right</Icon>
                                            <Icon>description</Icon> */}
                                            {docname}
                                           
                                            <Icon onClick={handleleft}>
                                                close
                                            </Icon>
                                        </header>
                                        <br />
                                       
                                        {valued == 'json' ? (
                                            <Paper
                                                elevation={20}
                                                style={{ padding: '5px' }}
                                            >
                                                <ReactJson src={im && im} />
                                            </Paper>
                                        ) : valued == 'xml' ? (
                                            <Paper
                                                elevation={20}
                                                style={{ padding: '5px' }}
                                            >
                                                {input && beautify(input)}
                                            </Paper>
                                        ) : // xmls
                                        valued == 'pdf' ? (
                                            <Paper
                                                elevation={20}
                                                style={{ padding: '5px' }}
                                            >
                                                <Document
                                                    file={pdf}
                                                    style={{
                                                        width: '300px !important',
                                                    }}
                                                    onLoadSuccess={
                                                        onDocumentLoadSuccess
                                                    }
                                                >
                                                    {Array.from(
                                                        new Array(numPages),
                                                        (el, index) => (
                                                            <Page
                                                                key={`page_${
                                                                    index + 1
                                                                }`}
                                                                pageNumber={
                                                                    index + 1
                                                                }
                                                            />
                                                        )
                                                    )}
                                                    {/* <Page pageNumber={pageNumber} /> */}
                                                </Document>
                                            </Paper>
                                        ) : (
                                            ''
                                        )}
                                       
                                    </div>
                                    {/* <div className="tile">
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            aria-label="simple tabs example"
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            indicatorColor="primary"
                                            textColor="primary"
                                        >
                                            <Tab
                                                label={
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                        }}
                                                    >
                                                        <Icon>
                                                            emoji_objects_outlined
                                                        </Icon>{' '}
                                                        &nbsp;Suggestions
                                                    </span>
                                                }
                                                {...a11yProps(0)}
                                                style={{
                                                    textTransform: 'none',
                                                    minWidth: '50px',
                                                }}
                                                onClick={() => handleClosess(0)}
                                            />

                                            <Tab
                                                label={
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                        }}
                                                    >
                                                        <Icon>
                                                            speaker_notes_outlined
                                                        </Icon>{' '}
                                                        Notes
                                                    </span>
                                                }
                                                {...a11yProps(1)}
                                                style={{
                                                    textTransform: 'none',
                                                    minWidth: '50px',
                                                }}
                                                onClick={() => handleClosess(1)}
                                            />
                                            
                                            <Tab
                                                label="Length of Stay (LOS) Analysis"
                                                {...a11yProps(3)}
                                                style={{
                                                    textTransform: 'none',
                                                    minWidth: '50px',
                                                }}
                                                //onClick={() => handleClosess(2)}
                                            />
                                            <Tab
                                                label="Avoidable Days"
                                                {...a11yProps(4)}
                                                style={{
                                                    textTransform: 'none',
                                                    minWidth: '50px',
                                                }}
                                                //onClick={() => handleClosess(2)}
                                            />
                                           
                                            <Tab
                                                label={
                                                    <span
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection:
                                                                'row',
                                                        }}
                                                    >
                                                        Medical Necessary&nbsp;
                                                        <Icon>
                                                            arrow_drop_down_icon
                                                        </Icon>
                                                    </span>
                                                }
                                                {...a11yProps(val)}
                                                style={{
                                                    textTransform: 'none',
                                                    minWidth: '50px',
                                                }}
                                                onClick={handleClick}
                                                // icon={<ArrowDropDownIcon />}
                                            />
                                        </Tabs>
                                 
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleClosess}
                                            // anchorOrigin={{
                                            //     vertical: 'bottom',
                                            //     horizontal: 'center',
                                            //   }}
                                            style={{
                                                marginTop: '50px',
                                                marginLeft: '50px',
                                            }}
                                        >
                                            <MenuItem
                                                onClick={() => handleClosess(2)}
                                            >
                                                Risk Score
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => handleClosess(3)}
                                            >
                                                MN Calc
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => handleClosess(4)}
                                            >
                                                Prediction
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => handleClosess(5)}
                                            >
                                                Certificate
                                            </MenuItem>
                                        </Menu>
                                        <Divider />
                                        {val == 0 ? (
                                            <div>
                                                <div>
                                                    <div
                                                        style={{
                                                            border: '1px solid #F3F6F9',
                                                            backgroundColor:
                                                                'white',
                                                        }}
                                                    >
                                                        <h6
                                                            style={{
                                                                backgroundColor:
                                                                    '#F3F6F9',
                                                                padding: '7px',
                                                            }}
                                                        >
                                                            All Suggestions
                                                        </h6>
                                                        <div id="ontainer" />
                                                       
                                                    </div>
                                                  
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                       
                                        {val == 1 ? (
                                            <div>
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                >
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Create Notes
                                                    </h6>
                                                    <form
                                                        onSubmit={notessubmit}
                                                    >
                                                        <TextareaAutosize
                                                            aria-label="minimum height"
                                                            minRows={3}
                                                            placeholder="create notes"
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            value={notes}
                                                            onChange={(e) =>
                                                                setnotes(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        <DialogActions>
                                                            <Button
                                                                type="submit"
                                                                size="small"
                                                                variant="outlined"
                                                            >
                                                                submit
                                                            </Button>
                                                        </DialogActions>
                                                    </form>
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Manual Notes
                                                    </h6>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                      
                                        {val == 2 ? (
                                            <div>
                                             
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                >
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Algoritham
                                                    </h6>
                                                    <hr />
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        High Risk of Adverse
                                                        Events within 96 hours
                                                    </h6>
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Manual Notes
                                                    </h6>
                                                    <img
                                                        src="assets/images/meter.png"
                                                        alt="meters"
                                                        width="100%"
                                                        height="auto"
                                                    />
                                                    <hr />
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Factors contributing
                                                        Score
                                                    </h6>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                      
                                        {val == 3 ? (
                                            <div>
                                             
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                >
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Review
                                                    </h6>

                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="dense"
                                                    >
                                                        <InputLabel id="demo-simple-select-outlined-label">
                                                            Review By
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={10}
                                                            //   onChange={handleChange}
                                                            label="Review By"
                                                        >
                                                            <MenuItem value="">
                                                                <em>None</em>
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={10}
                                                            >
                                                                Ten
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={20}
                                                            >
                                                                Twenty
                                                            </MenuItem>
                                                            <MenuItem
                                                                value={30}
                                                            >
                                                                Thirty
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        label="date of Review"
                                                        type="date"
                                                        margin="dense"
                                                    />
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        label="Time"
                                                        type="time"
                                                        margin="dense"
                                                    />

                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Critical Source
                                                    </h6>

                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Doctor Decision
                                                    </h6>
                                                </div>
                                                
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {val == 4 ? (
                                            <div>
                                               
                                                <div
                                                    style={{
                                                        backgroundColor:
                                                            'white',
                                                    }}
                                                >
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        All Suggestions
                                                    </h6>
                                                    <div
                                                        style={{
                                                            margin: '5px',
                                                        }}
                                                    >
                                                        <Paper
                                                            square
                                                            elevation={0}
                                                        >
                                                            <h6>
                                                                {' '}
                                                                {
                                                                    tutorialSteps[
                                                                        activeStep
                                                                    ].name
                                                                }
                                                            </h6>
                                                            <Typography>
                                                                {
                                                                    tutorialSteps[
                                                                        activeStep
                                                                    ].label
                                                                }
                                                            </Typography>
                                                        </Paper>

                                                        <MobileStepper
                                                            steps={maxSteps}
                                                            position="static"
                                                            variant="text"
                                                            activeStep={
                                                                activeStep
                                                            }
                                                            nextButton={
                                                                <Button
                                                                    size="small"
                                                                    onClick={
                                                                        handleNext
                                                                    }
                                                                    disabled={
                                                                        activeStep ===
                                                                        maxSteps -
                                                                            1
                                                                    }
                                                                >
                                                                    Next
                                                                    {theme.direction ===
                                                                    'rtl' ? (
                                                                        <KeyboardArrowLeft />
                                                                    ) : (
                                                                        <KeyboardArrowRight />
                                                                    )}
                                                                </Button>
                                                            }
                                                            backButton={
                                                                <Button
                                                                    size="small"
                                                                    onClick={
                                                                        handleBack
                                                                    }
                                                                    disabled={
                                                                        activeStep ===
                                                                        0
                                                                    }
                                                                >
                                                                    {theme.direction ===
                                                                    'rtl' ? (
                                                                        <KeyboardArrowRight />
                                                                    ) : (
                                                                        <KeyboardArrowLeft />
                                                                    )}
                                                                    Back
                                                                </Button>
                                                            }
                                                        />
                                                    </div>
                                                    <h6
                                                        style={{
                                                            backgroundColor:
                                                                '#F3F6F9',
                                                            padding: '7px',
                                                        }}
                                                    >
                                                        Prediction Score
                                                    </h6>
                                                </div>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div> */}
                                </Splitter>
                            </div>
                        </div>
                    ) : (
                       
                        ''
                    )}
                </Grid>
               
            </Grid>
        </div>
    )
}
export default ReviewsTab

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={1}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const tutorialSteps = [
    {
        label: 'Problems with walking and balance dizziness,tremour,los of consicicusness,controled motions, eposide of visual loss',
        name: 'Neurologic (Brain & Nerve)',
    },
    {
        label: 'Problems with walking and balance dizziness,tremour,los of consicicusness,controled motions, eposide of visual loss',
        name: 'Neurologic (Brain & Nerve)',
    },
    {
        label: 'Problems with walking and balance dizziness,tremour,los of consicicusness,controled motions, eposide of visual loss',
        name: 'Neurologic (Brain & Nerve)',
    },
    {
        label: 'Problems with walking and balance dizziness,tremour,los of consicicusness,controled motions, eposide of visual loss',
        name: 'Neurologic (Brain & Nerve)',
    },
    {
        label: 'Problems with walking and balance dizziness,tremour,los of consicicusness,controled motions, eposide of visual loss',
        name: 'Neurologic (Brain & Nerve)',
    },
]
const listitem = [
    {
        name: 'H&P Notes',
        desc: '5page pdf 250.15 KB',
        desc1: 'Added Dec 24,2020 at 4.15 am by Brandon Thompson',
    },
    {
        name: 'Progress Notes',
        desc: '5pages pdf 250.15 KB',
        desc1: 'Added Dec 24,2020 at 4.15 am by Brandon Thompson',
    },
    {
        name: 'Progress Notes',
        desc: '14pages pdf 5.5 MB',
        desc1: 'Added Dec 24,2020 at 11.15 am by Brandon Thompson',
    },
    {
        name: 'Discharge Summary',
        desc: '6pages pdf 250.15 KB',
        desc1: 'Added Dec 24,2020 at 4.15 am by Brandon Thompson',
    },
    {
        name: 'Medical Record',
        desc: '5pages pdf 250.15 KB',
        desc1: 'Added Dec 24,2020 at 4.15 am by Brandon Thompson',
    },
]

// React.useEffect(() => {
//     const storageRefs = storage.ref()
//     storageRefs
//         .child('csv1.png')
//         .getDownloadURL()
//         .then(function (url) {
//             setim(url)
//         })
//         .catch(function (error) {
//             console.log(error)
//         })
// }, [])
var formDef = {
    code: 'X-001',
    name: 'Demo form',
    templateOptions: {
        showFormHeader: false,
        hideFormControls: true,
        viewMode: 'lg',
    },
    items: [
        {
            questionCode: 'X-002',
            question: 'Eye color',
            answers: [
                { text: 'Brown', code: 'br' },
                { text: 'Green', code: 'c' },
                { text: 'Blue', code: 'bl' },
                { text: 'Other', code: '0' },
            ],
            dataType: 'CWE',
        },
    ],
}

const doctype = [
    {
        id: 1,
        value: 'HL7 Consult Note',
    },
    {
        id: 2,
        value: 'HL7 Diagnostic Imaging Report',
    },
    {
        id: 3,
        value: 'HL7 Discharge Summary',
    },
    {
        id: 4,
        value: 'HL7 History and Physical (H&P)',
    },
    {
        id: 5,
        value: 'HL7 Operative Note',
    },
    {
        id: 6,
        value: 'HL7 Procedure Note',
    },
    {
        id: 7,
        value: 'HL7 Unstructured Documents',
    },
    {
        id: 8,
        value: 'HL7 Progress Notes',
    },
    {
        id: 9,
        value: 'HL7 CCD',
    },
    {
        id: 10,
        value: 'HITSP/C84 Consult and H&P Note Document',
    },
    {
        id: 11,
        value: 'HITSP/C32 - Summary Documents Using HL7 CCD',
    },
    {
        id: 12,
        value: 'HITSP/C48 Referral and Discharge Summary Document Constructs',
    },
    {
        id: 13,
        value: 'HITSP/C62 Scanned Document',
    },
]
const columns = [
    {
        name: 'Name',
        label: 'Author',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'data',
        label: 'Doc Name',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'type',
        label: 'Doc Type',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'Date',
        label: 'Created',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value) =>
                moment(new Date(value.seconds * 1000)).format('DD-MM-YYYY'),
        },
    },
]

const advfilter = [
    {
        id: 1,
        val: 'date - When this document reference was created',
    },
    {
        id: 2,
        val: 'subject - Who/what is the subject of the document',
    },
    {
        id: 3,
        val: '_lastUpdated - When the resource version last changed',
    },
    {
        id: 4,
        val: 'description - Human-readable description',
    },
    {
        id: 5,
        val: 'language - Human language of the content (BCP-47)',
    },
    {
        id: 6,
        val: `type - Multiple Resources: 
        * [AllergyIntolerance](allergyintolerance.html): allergy | intolerance - Underlying mechanism (if known)
        * [Composition](composition.html): Kind of composition (LOINC if possible)
        * [DocumentManifest](documentmanifest.html): Kind of document set
        * [DocumentReference](documentreference.html): Kind of document (LOINC if possible)
        * [Encounter](encounter.html): Specific type of encounter
        * [EpisodeOfCare](episodeofcare.html): Type/class  - e.g. specialist referral, disease management`,
    },
    {
        id: 7,
        val: 'event - Main clinical acts documented',
    },
    {
        id: 8,
        val: 'relationship - Combination of relation and relatesTo',
    },
    {
        id: 9,
        val: 'authenticator - Who/what authenticated the document',
    },
    {
        id: 10,
        val: `identifier - Multiple Resources: 

        * [AllergyIntolerance](allergyintolerance.html): External ids for this item
        * [CarePlan](careplan.html): External Ids for this plan
        * [CareTeam](careteam.html): External Ids for this team
        * [Composition](composition.html): Version-independent identifier for the Composition
        * [Condition](condition.html): A unique identifier of the condition record
        * [Consent](consent.html): Identifier for this record (external references)
        * [DetectedIssue](detectedissue.html): Unique id for the detected issue
        * [DeviceRequest](devicerequest.html): Business identifier for request/order
        * [DiagnosticReport](diagnosticreport.html): An identifier for the report
        * [DocumentManifest](documentmanifest.html): Unique Identifier for the set of documents
        * [DocumentReference](documentreference.html): Master Version Specific Identifier
        * [Encounter](encounter.html): Identifier(s) by which this encounter is known
        * [EpisodeOfCare](episodeofcare.html): Business Identifier(s) relevant for this EpisodeOfCare
        * [FamilyMemberHistory](familymemberhistory.html): A search by a record identifier
        * [Goal](goal.html): External Ids for this goal
        * [ImagingStudy](imagingstudy.html): Identifiers for the Study, such as DICOM Study Instance UID and Accession number
        * [Immunization](immunization.html): Business identifier
        * [List](list.html): Business identifier
        * [MedicationAdministration](medicationadministration.html): Return administrations with this external identifier
        * [MedicationDispense](medicationdispense.html): Returns dispenses with this external identifier
        * [MedicationRequest](medicationrequest.html): Return prescriptions with this external identifier
        * [MedicationStatement](medicationstatement.html): Return statements with this external identifier
        * [NutritionOrder](nutritionorder.html): Return nutrition orders with this external identifier
        * [Observation](observation.html): The unique id for a particular observation
        * [Procedure](procedure.html): A unique identifier for a procedure
        * [RiskAssessment](riskassessment.html): Unique identifier for the assessment
        * [ServiceRequest](servicerequest.html): Identifiers assigned to this order
        * [SupplyDelivery](supplydelivery.html): External identifier
        * [SupplyRequest](supplyrequest.html): Business Identifier for SupplyRequest
        * [VisionPrescription](visionprescription.html): Return prescriptions with this external identifier
        `,
    },
    {
        id: 11,
        val: 'period - Time of service that is being documented',
    },
    {
        id: 12,
        val: 'custodian - Organization which maintains the document',
    },
    {
        id: 13,
        val: 'author - Who and/or what authored the document',
    },
    {
        id: 14,
        val: '_security - Security Labels applied to this resource',
    },
    {
        id: 15,
        val: 'format - Format/content rules for the document',
    },
    {
        id: 16,
        val: `encounter - Multiple Resources: 

        * [Composition](composition.html): Context of the Composition
        * [DeviceRequest](devicerequest.html): Encounter during which request was created
        * [DiagnosticReport](diagnosticreport.html): The Encounter when the order was made
        * [DocumentReference](documentreference.html): Context of the document  content
        * [Flag](flag.html): Alert relevant during encounter
        * [List](list.html): Context in which list created
        * [NutritionOrder](nutritionorder.html): Return nutrition orders with this encounter identifier
        * [Observation](observation.html): Encounter related to the observation
        * [Procedure](procedure.html): Encounter created as part of
        * [RiskAssessment](riskassessment.html): Where was assessment performed?
        * [ServiceRequest](servicerequest.html): An encounter in which this request is made
        * [VisionPrescription](visionprescription.html): Return prescriptions with this encounter identifier
        `,
    },
    {
        id: 17,
        val: '_filter - Search the contents of the resources data using a filter',
    },
    {
        id: 18,
        val: 'security-label - Document security-tags',
    },
    {
        id: 19,
        val: '_profile - Profiles this resource claims to conform to',
    },
    {
        id: 20,
        val: '_tag - Tags applied to this resource',
    },
    {
        id: 21,
        val: '_has - Return resources linked to by the given target',
    },
    {
        id: 22,
        val: '_source - Identifies where the resource comes from',
    },
    {
        id: 23,
        val: 'location - Uri where the data can be found',
    },

    {
        id: 24,
        val: '_id - Logical id of this artifact',
    },
    {
        id: 25,
        val: '_text - Search on the narrative of the resource',
    },
    {
        id: 26,
        val: '_content - Search on the entire content of the resource',
    },
    {
        id: 27,
        val: 'category - Categorization of document',
    },
    {
        id: 28,
        val: 'relatesto - Target of the relationship',
    },
    {
        id: 29,
        val: 'facility - Kind of facility where patient was seen',
    },
    {
        id: 30,
        val: 'status - current | superseded | entered-in-error',
    },
]

