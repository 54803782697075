import React, { useState } from 'react';
import { firebas as firebase } from '../MedicalNecessary/firebase';
import { usePatient } from '../PatientProvider';
import FHIR from 'fhirclient';
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  IconButton,
  Icon,
  TableRow,
  TableContainer,
  Paper,
} from '@mui/material';
const Insurance = () => {
  const firestore = firebase.firestore();

  const patient = usePatient();
  const [insu, setinsu] = React.useState();
  React.useEffect(() => {
    firestore
      .collection(`patient/${patient.id}/coverage`)
      .get()
      .then((data) => {
        // console.log(data);
        let pdata = [];
        data.forEach((doc) => {
          pdata.push(doc.data());
        });
        console.log(pdata);
        return setinsu(pdata);
      })
      .catch((err) => console.error(err));
  }, []);
  const data =
    insu &&
    insu.map((res) => (res.data !== undefined && res.data !== '' ? JSON.parse(res.data) : null));
  //   let data =
  // cov &&
  // cov.map((res) => (res.data !== undefined && res.data !== "" ? JSON.parse(res.data) : null))
  //const [cove, setcove] = useState('')
  // React.useEffect(() => {
  //   FHIR.oauth2
  //     .ready()
  //     .then((client) => client.request('Coverage'))
  //     .then((res) => {
  //       setcove(res);
  //     });
  // }, []);
  //   React.useEffect(()=>{
  //     db.collection(`patient/${localStorage.getItem('patient')}/coverage`)
  //       .get()
  //     .then((data) => {
  //         // console.log(data);
  //         let pdata = []
  //         data.forEach((document) => {
  //             const ddo = {
  //                  id: document.id,
  //                 ...document.data(),
  //             }
  //             pdata.push(ddo)
  //         })
  //         // return setpatients(pdata)
  //         // data.forEach((doc) => {
  //         //      id: document.id,
  //         //     ...document.data(),

  //         //     pdata.push(doc.data())
  //         // })
  //        // console.log(pdata)
  //         return setcov(pdata)
  //     })
  //     .catch((err) => console.error(err))

  // },[])

  return (
    <div>
      {/* {console.log(data)}
      {data && data == '' ? <p>no insurance information avaliable</p> : ''} */}
      <div component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Inc Company</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Plan Type</TableCell>
              <TableCell>Primary ID</TableCell>
              <TableCell>Policy/Group #</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data !== null &&
              data.map(
                (res, index) =>
                  res !== null &&
                  res.map((re, index) => (
                    <TableRow key={index}>
                      <TableCell>{re.network}</TableCell>
                      <TableCell>
                        {(re.type && re.type.coding && re.type.coding[0].display) || ''}
                      </TableCell>
                      <TableCell>{re.order}</TableCell>
                      <TableCell>{re.subscriberId}</TableCell>
                      <TableCell>{(re.subscriber && re.subscriber.reference) || ''}</TableCell>
                    </TableRow>
                  ))
              )}
          </TableBody>
        </Table>
      </div>
      {/* <table style={{ width: '100%' }}>
        <thead>
          <tr>
            <td className="thead">Inc Company</td>
            <td className="thead">Type</td>
            <td className="thead">Plan Type</td>
            <td className="thead">Primary ID</td>
            <td className="thead">Policy/Group #</td>
          </tr>
        </thead>
        <tbody>
          {ttt.map((res, index) => (
            <tr key={index}>
              <td className="trow">{res.Company}</td>
              <td className="trow">{res.Type}</td>
              <td className="trow">{res.PlanType}</td>
              <td className="trow">{res.PrimaryID}</td>
              <td className="trow">{res.PolicyGroup}</td>
            </tr>
          ))}
        </tbody>
      </table> */}
    </div>
  );
};
export default Insurance;
const ttt = [
  {
    Company: 'Medicare ‘A’ - Atena',
    Type: 'Primary',
    PlanType: 'Medicare Part B',
    PrimaryID: 4251326,
    PolicyGroup: 8985,
  },
  {
    Company: 'Medicare ‘B’ - Arizona',
    Type: 'Secondary',
    PlanType: 'Medicare Part B',
    PrimaryID: 3236346,
    PolicyGroup: 8575,
  },
];
