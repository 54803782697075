import React, { useState, createContext } from 'react'
import FHIR from 'fhirclient';
export const encounterContext = createContext()

export const EncounterProvider = ({ children }) => {
  const [dataView, setDataView] = useState('')
  React.useEffect(() => {
    FHIR.oauth2
      .ready()
      .then((client) => client.request('Encounter'))
      .then((res) => {
        enn(res.entry);
      });
  }, []);
  const enn = (e) => {
   // console.log(e)
    if (e && e) {
      const sortByDate = (e) => {
        const sorter = (a, b) => {
          return new Date(a.resource.period).getTime() - new Date(b.resource.period).getTime();
        };
        e.sort(sorter);
      };
      sortByDate(e);
      setDataView(e[e.length - 1]);
    }
  };
  const value = {
    dataView,
  }

  return <encounterContext.Provider value={value}>{children}</encounterContext.Provider>
}