import React from 'react';
import { Grid, Divider, Box, Card, CardContent, Typography, Button } from '@mui/material';
import Guage from './guage';
const Ai = () => {
  function generate(element: React.ReactElement) {
    return [0, 1, 2, 4, 5].map((value) =>
      React.cloneElement(element, {
        key: value,
      })
    );
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>AI Prediction</p>
          <div style={{ textAlign: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: '80px', marginLeft: '20px' }}>
                <p style={{ fontSize: '16px', fontWeight: 700 }}>Observation</p>
              </div>
              <div>
                <Guage />
              </div>
              <div style={{ marginTop: '80px' }}>
                <p style={{ fontSize: '16px', fontWeight: 700, position: 'absolute' }}>Inpatient</p>
              </div>
            </div>

            {/* <img src="static/images/Risk dial.svg" width="30%" />&nbsp;&nbsp; */}
          </div>

          <Divider />
          <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>
            Factros Contributing towards score
          </p>
          <div style={{marginLeft:'50px' }}>
            {list.map((line) => (
              <Typography variant="subtitle2" display="block" key={line}>
                {line}
              </Typography>
            ))}
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>AI Suggestions</p>
          <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: '85px' }}>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#FA4900',
                    position: 'absolute',
                  }}
                >
                  OBS
                </p>
              </div>
              <div>
                <Guage />
              </div>
              <div style={{ marginTop: '85px', marginLeft: '-30px' }}>
                <p
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: ' #005DFF',
                    position: 'absolute',
                  }}
                >
                  IN
                </p>
              </div>
            </div>

            <Divider />
          </div>
          <Box sx={{ p: 2 }}>
            <p
              style={{
                fontSize: '14px',
                padding: '0px !important',
                color: '#3E4F7C',
                fontWeight: 500,
              }}
            >
              Suggestion &nbsp;
              <span
                style={{
                  backgroundColor: '#F34036',
                  borderRadius: '4px',
                  color: 'white',
                  padding: '2px',
                }}
              >
                5
              </span>
            </p>

            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography variant="caption" color="text.secondary">
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </CardContent>
              {/* <CardActions> */}
              <Box sx={{ p: 1 }}>
                <Button startIcon={<i className="fa fa-question-circle" />} size="small">
                  Learn More
                </Button>
                <div style={{ float: 'right' }}>
                  <i className="fa fa-trash" aria-hidden="true" />
                  &nbsp;&nbsp;
                </div>
              </Box>

              {/* </CardActions> */}
            </Card>
          </Box>
          <Box sx={{ p: 2 }}>
            {generate(
              <Box sx={{ backgroundColor: 'white', mb: 1 }} className="ca">
                <p style={{ padding: '2px' }}>
                  {' '}
                  <span style={{ color: '#F34036' }}>●</span> Lorem ipsum dolor sit amet,
                  consectetur adipd.
                </p>
              </Box>
            )}
          </Box>
          <Box sx={{ p: 2 }}>
            <p
              style={{
                fontSize: '14px',
                padding: '0px !important',
                color: '#3E4F7C',
                fontWeight: 500,
              }}
            >
              UM Suggestions &nbsp;
              <span
                style={{
                  backgroundColor: '#F34036',
                  borderRadius: '4px',
                  color: 'white',
                  padding: '2px',
                }}
              >
                5
              </span>
            </p>

            {generate(
              <Box sx={{ backgroundColor: 'white', mb: 1 }} className="ca">
                <p style={{ padding: '2px' }}>
                  <span style={{ color: '#F34036' }}>●</span> Adam Davidson
                </p>
                <p style={{ marginLeft: '10px' }}>Patient: Thomas, Cameron</p>
                <p style={{ marginLeft: '10px' }}>Subject: CDI QUERY</p>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default Ai;
const list = [
  '61%		O2 Deice is greater than Nasal cannula',
  '9%		RR Max in last 12 hr is 36',
  '7%		Blood urea nitrogen Last is 39',
  '4%		C-reactive protein Last is 256',
  '2%		HR Min in last 12 hr is 77',
  '1%		Temp Max in last 12 hr is 98.2',
  '-4%		Nasak cannula O2 flow rate Max in last 12 hr is N/A',
  '-4%		SpO2 Min in last 12 hr is 91',
  '-8%		Eosinophils % Last is 7',
];
