import React from 'react';
import GaugeChart from 'react-gauge-chart';
const Guage = () => {
  return (
    <div>
     
      <GaugeChart id="gauge-chart2" nrOfLevels={20} percent={0.86}  />
    </div>
  );
};
export default Guage;
