import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/storage';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { useCollectionData } from 'react-firebase-hooks/firestore';

var a =firebase.initializeApp({
  apiKey: "AIzaSyB-WNzDSLcurwmX_GKPJE5BwPyk-DL4MMg",
  authDomain: "smartonfhir-274312.firebaseapp.com",
  databaseURL: "https://smartonfhir-274312.firebaseio.com",
  projectId: "smartonfhir-274312",
  storageBucket: "smartonfhir-274312.appspot.com",
  messagingSenderId: "1057419632244",
  appId: "1:1057419632244:web:20e4c78ebee47a01793b21"
  // apiKey: 'AIzaSyDj1MDjk4XJ5KL5h35MNJwYAvx3ZbCcDu8',
  // authDomain: 'platform-gateway-b601f.firebaseapp.com',
  // projectId: 'platform-gateway-b601f',
  // storageBucket: 'platform-gateway-b601f.appspot.com',
  // messagingSenderId: '1035682625980',
  // appId: '1:1035682625980:web:5218d9fc01aa2b32ba93b9',
  // measurementId: 'G-2503827391',
});
// export const firebas = firebas 

export var firebas = a;
export const storage = firebase.storage(); 




// import firebase from 'firebase/compat/app';
// import 'firebase/compat/firestore';
// import 'firebase/compat/storage';
// var firebaseConfig = {
//     apiKey: 'AIzaSyB-WNzDSLcurwmX_GKPJE5BwPyk-DL4MMg',
//     authDomain: 'smartonfhir-274312.firebaseapp.com',
//     databaseURL: 'https://smartonfhir-274312.firebaseio.com',
//     projectId: 'smartonfhir-274312',
//     storageBucket: 'smartonfhir-274312.appspot.com',
//     messagingSenderId: '1057419632244',
//     appId: '1:1057419632244:web:20e4c78ebee47a01793b21',
// }
// firebase.initializeApp(firebaseConfig)
// const db = firebase.firestore()
// // export default db
// export default db;
// export const storage = firebase.storage(); 