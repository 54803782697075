// import React, { FC } from 'react';
// import {
//   AllergiesVisualizer,
//   CarePlansVisualizer,
//   //CommunicationVisualizer,
//   ConditionsVisualizer,
//   //CoverageVisualizer,
//  // DeviceRequestVisualizer,
//   EncountersVisualizer,
//   ImmunizationsVisualizer,
//   //MedicationAdministrationVisualizer,
//   //MedicationDispenseVisualizer,
//   //MedicationRequestVisualizer,
//   MedicationsVisualizer,
//   //NutritionOrderVisualizer,
//   ObservationsVisualizer,
//   ProceduresVisualizer,
//   ReportsVisualizer,
//   //ServiceRequestVisualizer,
// } from 'fhir-visualizers';
// import { usePatient } from '../PatientProvider';

// type PatientRecordProps = {
//   resources: ReadonlyArray<Record<string, any>>;
// };

// const getResourceByType = (patientRecord: ReadonlyArray<any>, resourceType: string) => {
//   return patientRecord.filter((resource) => resource.resourceType === resourceType);
// };

// //const PatientRecord: FC<PatientRecordProps> = ({ resources }) => {
// // const patient = usePatient();
// // const [reso,setreso]=React.useState(resources)
// // React.useEffect(() => {

// //   setreso(resources)
// // }, [resources]);
// const FhirResourceList: FC<PatientRecordProps> = ({ resources }) => {
//   const patient = usePatient();
//   const [reso, setreso] = React.useState(resources);
//   React.useEffect(() => {
//     setreso(resources);
//   }, [resources]);
//   return (
//     <div>
//       {/* <PatientVisualizer patient={patient} /> */}
//       {/* {console.log(getResourceByType(resources, 'Encounter'))} */}
//       {/* <AdverseEventVisualizer rows={getResourceByType(resources, 'AdverseEvent')} /> */}
//       {/* <CoverageVisualizer rows={getResourceByType(resources, 'Coverage')} /> */}
//       {/* //<CommunicationVisualizer rows={getResourceByType(resources, 'Communication')} /> */}
//       {/* <DeviceRequestVisualizer rows={getResourceByType(resources, 'DeviceRequest')} /> */}
//       {/* <DocumentReferencesVisualizer rows={getResourceByType(resources, 'Encounter')} /> */}
//       {/* <MedicationDispenseVisualizer rows={getResourceByType(resources, 'MedicationDispense')} />
//       <MedicationRequestVisualizer rows={getResourceByType(resources, 'MedicationRequest')} />
//       <NutritionOrderVisualizer rows={getResourceByType(resources, 'NutritionOrder')} />
//       <MedicationAdministrationVisualizer
//         rows={getResourceByType(resources, 'MedicationAdministration')}
//       /> */}
//       {/* <PatientVisualizer rows={getResourceByType(resources, 'Encounter')} /> */}
//       {/* <ResourceVisualizer rows={getResourceByType(resources, 'Encounter')} /> */}
//       {/* <ServiceRequestVisualizer rows={getResourceByType(resources, 'ServiceRequest')} /> */}

//       <EncountersVisualizer rows={getResourceByType(resources, 'Encounter')} />
//       <ConditionsVisualizer rows={getResourceByType(resources, 'Condition')} />
//       <ObservationsVisualizer rows={getResourceByType(resources, 'Observation')} />
//       <ReportsVisualizer rows={getResourceByType(resources, 'DiagnosticReport')} />
//       <MedicationsVisualizer rows={getResourceByType(resources, 'MedicationRequest')} />
//       <AllergiesVisualizer rows={getResourceByType(resources, 'AllergyIntolerance')} />
//       <CarePlansVisualizer rows={getResourceByType(resources, 'CarePlan')} />
//       <ProceduresVisualizer rows={getResourceByType(resources, 'Procedure')} />
//       {/* <EncountersVisualizer rows={getResourceByType(resources, 'Encounter')} /> */}

//       <ImmunizationsVisualizer rows={getResourceByType(resources, 'Immunization')} />
//     </div>
//   );
// };
// export default FhirResourceList;
// if (resourceType === "Patient")
// return <PatientVisualizer patient={this.props.patient} />;
// else if (resourceType === "Condition")
// return <ConditionsVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "Observation")
// return <ObservationsVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass} />;
// else if (resourceType === "DiagnosticReport")
// return <ReportsVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "Medication")
// return <MedicationsVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "AllergyIntolerance")
// return <AllergiesVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "CarePlan")
// return <CarePlansVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "Procedure")
// return <ProceduresVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "Encounter")
// return <EncountersVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "Immunization")
// return <ImmunizationsVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "ServiceRequest")
// return <ServiceRequestVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "DeviceRequest")
// return <DeviceRequestVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "Communication")
// return <CommunicationVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "Coverage")
// return <CoverageVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "AdverseEvent")
// return <AdverseEventVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "NutritionOrder")
// return <NutritionOrderVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "MedicationRequest")
// return <MedicationRequestVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "MedicationAdministration")
// return <MedicationAdministrationVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// else if (resourceType === "MedicationDispense")
// return <MedicationDispenseVisualizer rows={this.props.rows} onRowClick={this.props.onRowClick} dynamicRowClass={this.props.dynamicRowClass}/>;
// }
import React, { useState } from 'react'
import PropTypes from 'prop-types'
//import { makeStyles } from '@material-ui/core/styles'
import { Tabs, Tab, Box, Button } from '@mui/material'
import {
    Table,
    TableHead,
    TableCell,
    TableBody,
    IconButton,
    Icon,
    TableRow,
    Grid,
} from '@mui/material'
import Moment from 'moment'
import moment from 'moment'
// import Coverage from '../coverage'
// import Encounter from './enncounter'
import Timeline from 'react-visjs-timeline'
//import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { FhirResource, fhirVersions, fhirIcons } from 'fhir-react'
//import EncounterForm from './encoform/encoform'
import 'fhir-react/build/style.css';
import 'fhir-react/build/bootstrap-reboot.min.css';
import 'fhir-react/build/style.css'
import 'fhir-react/build/bootstrap-reboot.min.css'
import './FhirResourceList.scss'

// const theme = createTheme({
//     overrides: {
//         MuiTab: {
//             wrapper: {
//                 alignItems: 'flex-start !important',
//             },
//         },
//     },
// })
const Observation = (props) => {
    const [data, setdata] = React.useState(props && props.value)
    const [cplan, setcplan] = React.useState(props && props.CarePlan)
    const [Condition, setCondition] = React.useState(props && props.Condition)
    const [Observation, setObservation] = React.useState(
        props && props.Observation
    )
    const [AllergyIntolerance, setAllergyIntolerance] = React.useState(
        props && props.AllergyIntolerance
    )
    const [Procedure, setProcedure] = React.useState(props && props.Procedure)
    const [Immunization, setImmunization] = React.useState(
        props && props.Immunization
    )
    const [MedicationRequest, setMedicationRequest] = React.useState(
        props && props.MedicationRequest
    )
    const [MedicationDispense, setMedicationDispense] = useState(
        props && props.MedicationDispense
    )
    const [enco, setenco] = useState(props && props.enco)
    React.useEffect(() => {
        setdata(props.value)
        setcplan(props.CarePlan)
        setCondition(props.Condition)
        setObservation(props.Observation)
        setAllergyIntolerance(props.AllergyIntolerance)
        setProcedure(props.Procedure)
        setImmunization(props.Immunization)
        setMedicationRequest(props.MedicationRequest)
        setMedicationDispense(props.MedicationDispense)
        setenco(props.enco)
        enview(props.value)
    }, [props])
    ///
    const [dt, setdt] = React.useState([])
    const enview = (rrr) => {
        const pdata = []
        rrr &&
            rrr.map((response, index) => {
                const ddo = {
                    id: response.id,
                    content: `index  ${index}`,
                    title: response.id,
                    //content: `<div><div>${response.id}</div></div>`,
                    ...response.period,
                }
                pdata.push(ddo)

                return setdt(pdata)
            })

        //
        // const fetcheddata = [];
        // Promise.all(
        //     rrr &&
        //     rrr.map((id) => {
        //       return new Promise((resolve) => {
        //         // firestore
        //         //   .collection('patient')
        //         //   .doc(id.id)
        //         //   .collection('inbox')
        //         //   .get()
        //          // .then((response) => {
        //             return new Promise(() => {
        //             //   response.forEach((document) => {
        //             //     const fetchedpat = {
        //             //       //id: document.id,
        //             //       ...document.data(),
        //             //     };
        //             //     fetcheddata.push(fetchedpat);
        //             //     resolve();
        //             //   });

        //             });
        //          // });
        //       });
        //     })
        // ).then(() => {
        //   setpatients(fetcheddata)
        //   ///console.log(fetcheddata);
        // });
        //
    }

    ///
    //const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const vis = data && data.map((res, index) => res.period)
    const [showfhir, setshowfhir] = React.useState(false)
    const [fhirdata, setfhirdata] = React.useState([])
    const handefhir = (re) => {
        setshowfhir(true)
        setfhirdata(re)
    }
    const handefhirclose = () => {
        setshowfhir(false)
    }
    const [viewen,setvieen]=React.useState(false)
    const handlevien=()=>{
        setvieen(!viewen)
    }
    const [entime,setentime]=React.useState()
    const selectHandler = (pr) => {
        //  console.log(pr.items[0])
        if(pr){
            let a = data.filter((x) => x.id === pr.items[0]).map((x) => x)
            setentime(a[0])
            setvieen(true)
        }
       
    }
    
    return (
        <div>
            {/* {console.log(props)} */}
            {showfhir ? (
                <div>
                    <Button variant="outlined" onClick={handefhirclose}>
                        back
                    </Button>
                    <FhirResource
                        fhirResource={fhirdata && fhirdata}
                        fhirVersion={fhirVersions.R4}
                    />
                </div>
            ) : (
                <div 
                //className={classes.root}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={2}>
                            {/* <ThemeProvider theme={theme}> */}
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    //className={classes.tabs}
                                >
                                    <Tab
                                        label="Observation"
                                        {...a11yProps(0)}
                                        // className={classes.MuiTab}
                                        style={{
                                            textTransform: 'none',
                                            alignItems: 'flex-start !important',
                                        }}
                                    />
                                    <Tab
                                        label="AllergyIntolerance"
                                        {...a11yProps(1)}
                                        style={{
                                            textTransform: 'none',
                                            alignItems: 'flex-start !important',
                                        }}
                                    />
                                    <Tab
                                        label="Coverage"
                                        {...a11yProps(2)}
                                        style={{ textTransform: 'none' }}
                                    />
                                    <Tab
                                        label="Encounter"
                                        {...a11yProps(3)}
                                        style={{ textTransform: 'none' }}
                                    />
                                    <Tab
                                        label="Immunization"
                                        {...a11yProps(4)}
                                        style={{ textTransform: 'none' }}
                                    />
                                    <Tab
                                        label="Condition"
                                        {...a11yProps(5)}
                                        style={{ textTransform: 'none' }}
                                    />
                                    <Tab
                                        label="EpisodeOfCare"
                                        {...a11yProps(6)}
                                        style={{ textTransform: 'none' }}
                                    />
                                    <Tab
                                        label="Procedure"
                                        {...a11yProps(7)}
                                        style={{ textTransform: 'none' }}
                                    />
                                    <Tab
                                        label="MedicationRequest"
                                        {...a11yProps(8)}
                                        style={{ textTransform: 'none' }}
                                    />
                                    <Tab
                                        label="MedicationDispense"
                                        {...a11yProps(9)}
                                        style={{ textTransform: 'none' }}
                                    />
                                    {/* <Tab
                                label="Coverage"
                                {...a11yProps(10)}
                                style={{ textTransform: 'none' }}
                            /> */}
                                </Tabs>
                            {/* </ThemeProvider> */}
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <TabPanel value={value} index={0}>
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Value</TableCell>
                                                <TableCell>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Observation &&
                                                Observation.map(
                                                    (res, index) => (
                                                        <TableRow
                                                            key={index}
                                                            onClick={() =>
                                                                handefhir(res)
                                                            }
                                                        >
                                                            <TableCell>
                                                                {(res.code &&
                                                                    res.code
                                                                        .text) ||
                                                                    ''}
                                                            </TableCell>
                                                            <TableCell>
                                                                {(res.code &&
                                                                    res.code
                                                                        .coding &&
                                                                    res.code
                                                                        .coding[0]
                                                                        .code) ||
                                                                    ''}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Date
                                                                    moment={
                                                                        res.effectiveDateTime
                                                                    }
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Identifier
                                                </TableCell>
                                                <TableCell>Details</TableCell>
                                                <TableCell>
                                                    criticality
                                                </TableCell>

                                                <TableCell>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {AllergyIntolerance &&
                                                AllergyIntolerance.map(
                                                    (res, index) => (
                                                        <TableRow
                                                            key={index}
                                                            onClick={() =>
                                                                handefhir(res)
                                                            }
                                                        >
                                                            <TableCell>
                                                                {res.id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {(res.code &&
                                                                    res.code
                                                                        .coding &&
                                                                    res.code
                                                                        .coding[0]
                                                                        .display) ||
                                                                    ''}
                                                            </TableCell>
                                                            <TableCell>
                                                                {res.criticality ||
                                                                    ''}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Date
                                                                    moment={
                                                                        res.recordedDate
                                                                    }
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {/* <Coverage /> */}
                            </TabPanel>

                            <TabPanel value={value} index={3}>
                                {/* {console.log(dt)} */}
                                <Timeline
                                    //     options={options}
                                    //     items={vis}
                                    //    // items={vis}
                                    //    selectHandler={selectHandler}
                                    selectHandler={selectHandler}
                                    orientation="top"
                                    options={options}
                                    items={dt}
                                    // customTimes={customTimes}
                                    showCurrentTime="true"
                                />
                                {/* <Encounter enco={enco} /> */}
                               {/* {viewen?<div> */}
                               {!viewen? <div>
                                {/* <EncounterForm /> */}
                                <div className="w-full overflow-auto">
                                    <Table className="whitespace-pre">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="px-0">
                                                    {/* //type    */} id
                                                </TableCell>
                                                <TableCell className="px-0">
                                                    Reason
                                                </TableCell>
                                                <TableCell className="px-0">
                                                    Class
                                                </TableCell>
                                                <TableCell className="px-0">
                                                    Status
                                                </TableCell>
                                                <TableCell className="px-0">
                                                    Time
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data &&
                                                data.map((res, index) => (
                                                    <TableRow
                                                        key={index}
                                                        onClick={() =>
                                                            handefhir(res)
                                                        }
                                                    >
                                                        <TableCell>
                                                            {res.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {res.status || ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {Period(
                                                                res.period || {}
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                    </div>
                                </div>:
                                 <div>
                                 <Button variant="outlined" size='small' style={{float:'right'}} onClick={handlevien}>
                                     back
                                 </Button>
                                 <FhirResource
                                     fhirResource={entime}
                                     fhirVersion={fhirVersions.R4}
                                 />
                             </div>
                               
                               }
                            </TabPanel>
                            <TabPanel value={value} index={4}>
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Type</TableCell>
                                                <TableCell>Status</TableCell>
                                                <TableCell>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Immunization &&
                                                Immunization.map(
                                                    (res, index) => (
                                                        <TableRow
                                                            key={index}
                                                            onClick={() =>
                                                                handefhir(res)
                                                            }
                                                        >
                                                            <TableCell>
                                                                {(res.vaccineCode &&
                                                                    res
                                                                        .vaccineCode
                                                                        .coding &&
                                                                    res
                                                                        .vaccineCode
                                                                        .coding[0]
                                                                        .display) ||
                                                                    ''}
                                                            </TableCell>
                                                            <TableCell>
                                                                {res.status ||
                                                                    'N/A'}
                                                            </TableCell>

                                                            <TableCell>
                                                                <Date
                                                                    moment={
                                                                        res.occurrenceDateTime
                                                                    }
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={5}>
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Condition</TableCell>
                                                <TableCell>
                                                    Clinical Status
                                                </TableCell>
                                                <TableCell>
                                                    Verification Status
                                                </TableCell>
                                                <TableCell>
                                                    Onset Date
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Condition &&
                                                Condition.map((res, index) => (
                                                    <TableRow
                                                        key={index}
                                                        onClick={() =>
                                                            handefhir(res)
                                                        }
                                                    >
                                                        <TableCell>
                                                            {(res.code &&
                                                                res.code
                                                                    .text) ||
                                                                ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {(res.clinicalStatus &&
                                                                res
                                                                    .clinicalStatus
                                                                    .coding &&
                                                                res
                                                                    .clinicalStatus
                                                                    .coding[0]
                                                                    .code) ||
                                                                ''}
                                                        </TableCell>
                                                        <TableCell>
                                                            {res.verificationStatus &&
                                                                res
                                                                    .verificationStatus
                                                                    .coding &&
                                                                res
                                                                    .verificationStatus
                                                                    .coding[0]
                                                                    .code}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Date
                                                                moment={
                                                                    res.onsetDateTime
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={6}>
                                Item Seven
                            </TabPanel>
                            <TabPanel value={value} index={7}>
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Identifier
                                                </TableCell>
                                                <TableCell>Details</TableCell>
                                                {/* <TableCell>criticality</TableCell> */}
                                                <TableCell>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {Procedure &&
                                                Procedure.map((res, index) => (
                                                    <TableRow
                                                        key={index}
                                                        onClick={() =>
                                                            handefhir(res)
                                                        }
                                                    >
                                                        <TableCell>
                                                            {res.id}
                                                        </TableCell>
                                                        <TableCell>
                                                            {(res.code &&
                                                                res.code
                                                                    .coding &&
                                                                res.code
                                                                    .coding[0]
                                                                    .display) ||
                                                                ''}
                                                        </TableCell>

                                                        <TableCell>
                                                            <Date
                                                                moment={
                                                                    res.performedDateTime
                                                                }
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={8}>
                                <div className="w-full overflow-auto">
                                    <Table className="whitespace-pre">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="px-0">
                                                    Identifier
                                                </TableCell>
                                                <TableCell className="px-0">
                                                    Details
                                                </TableCell>
                                                <TableCell className="px-0">
                                                    status
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {MedicationRequest &&
                                                MedicationRequest.map(
                                                    (res, index) => (
                                                        <TableRow
                                                            key={index}
                                                            onClick={() =>
                                                                handefhir(res)
                                                            }
                                                        >
                                                            <TableCell
                                                                className="px-0 capitalize"
                                                                align="left"
                                                            >
                                                                {res.id}
                                                            </TableCell>
                                                            <TableCell
                                                                className="px-0 capitalize"
                                                                align="left"
                                                            >
                                                                {(res.medicationCodeableConcept &&
                                                                    res
                                                                        .medicationCodeableConcept
                                                                        .coding &&
                                                                    res
                                                                        .medicationCodeableConcept
                                                                        .coding[0]
                                                                        .display) ||
                                                                    ''}
                                                            </TableCell>
                                                            <TableCell
                                                                className="px-0 capitalize"
                                                                align="left"
                                                            >
                                                                {res.status}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </TabPanel>
                            <TabPanel value={value} index={9}>
                                <div>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    Identifier
                                                </TableCell>
                                                <TableCell>Details</TableCell>
                                                <TableCell>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {MedicationDispense &&
                                                MedicationDispense.map(
                                                    (res, index) => (
                                                        <TableRow
                                                            key={index}
                                                            onClick={() =>
                                                                handefhir(res)
                                                            }
                                                        >
                                                            <TableCell>
                                                                {res.id}
                                                            </TableCell>
                                                            <TableCell>
                                                                {(res.medicationCodeableConcept &&
                                                                    res
                                                                        .medicationCodeableConcept
                                                                        .text) ||
                                                                    ''}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Date
                                                                    moment={
                                                                        res.whenHandedOver
                                                                    }
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                        </TableBody>
                                    </Table>
                                </div>
                            </TabPanel>
                        </Grid>
                    </Grid>
                </div>
            )}
        </div>
    )
}
export default Observation

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={1}>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

// const useStyles = makeStyles((theme) => ({
//     root: {
//         flexGrow: 1,
//         backgroundColor: theme.palette.background.paper,
//         display: 'flex',
//         //height: 224,
//     },
//     tabs: {
//         borderRight: `1px solid ${theme.palette.divider}`,
//         // MuiTab-wrapper
//         //alignItems: "flex-end"
//         //right:0
//     },
//     // tabs: {
//     //     '&:last-child': {
//     //      // position: 'absolute',
//     //       right: '0'
//     //     }
//     //   },
//     wrapper: {
//         alignItems: 'flex-start',
//         textAlign: 'right',
//     },
// }))

export function getPath(obj, path = '') {
    return path
        .split('.')
        .reduce((out, key) => (out ? out[key] : undefined), obj)
}

// const subscribarList = [
//     {
//         name: 'john doe',
//         date: '18 january, 2019',
//         amount: 1000,
//         status: 'close',
//         company: 'ABC Fintech LTD.',
//     },
//     {
//         name: 'kessy bryan',
//         date: '10 january, 2019',
//         amount: 9000,
//         status: 'open',
//         company: 'My Fintech LTD.',
//     },
//     {
//         name: 'james cassegne',
//         date: '8 january, 2019',
//         amount: 5000,
//         status: 'close',
//         company: 'Collboy Tech LTD.',
//     },
//     {
//         name: 'lucy brown',
//         date: '1 january, 2019',
//         amount: 89000,
//         status: 'open',
//         company: 'ABC Fintech LTD.',
//     },
//     {
//         name: 'lucy brown',
//         date: '1 january, 2019',
//         amount: 89000,
//         status: 'open',
//         company: 'ABC Fintech LTD.',
//     },
//     {
//         name: 'lucy brown',
//         date: '1 january, 2019',
//         amount: 89000,
//         status: 'open',
//         company: 'ABC Fintech LTD.',
//     },
// ]

function Date({ moment, format = 'MM/DD/YYYY', ...rest }) {
    return <span {...rest}>{Moment(moment).format(format)}</span>
}
function Period(period) {
    let from = period.start || ''
    let to = period.end || ''

    if (from && to) {
        from = moment(from)
        to = moment(to)

        if (from.isSame(to, 'day')) {
            return (
                <span>
                    {from.format('MM/DD/YYYY')}
                    {from.isSame(to, 'hour') ? null : (
                        <span>
                            <small className="text-muted"> from </small>
                            {from.format('HH:mm')}
                            <small className="text-muted"> to </small>
                            {to.format('HH:mm')}
                        </span>
                    )}
                </span>
            )
        } else {
            return (
                <span>
                    <small className="text-muted"> from </small>
                    {from.format('MM/DD/YYYY')}
                    <small className="text-muted"> to </small>
                    {to.format('MM/DD/YYYY')}
                </span>
            )
        }
    } else {
        if (from) {
            from = moment(from)
            return (
                <span>
                    <small className="text-muted"> from </small>
                    {from.format('MM/DD/YYYY')}
                </span>
            )
        } else if (to) {
            to = moment(to)
            return (
                <span>
                    <small className="text-muted"> to </small>
                    {to.format('MM/DD/YYYY')}
                </span>
            )
        }
    }
    return <small className="text-muted">N/A</small>
}

const options = {
    width: '100%',
    height: '100px',
    stack: false,
    // orientation: "top",
    //     stack: false,
    // showMajorLabels: true,
    // showCurrentTime: true,
    // zoomMin: 1000000,
    // type: 'background',
    orientation: 'top',

    //timeAxis: { scale: "week", step: 1 },
    ///////////
}
