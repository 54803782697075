import React, { FC, createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { useFHIRClient } from './FHIRClient';
//import FHIR from 'fhirclient';
interface PractitionerProviderProps {
  children: ReactNode;
}

export const PractitionerContext = createContext<fhir.Practitioner | null>(null);

export const PractitionerProvider: FC<PractitionerProviderProps> = ({ children }) => {
  const client = useFHIRClient();
  const [practitioner, setpractitioner] = useState<fhir.Practitioner | null>(null);

  useEffect(() => {
   client.user.read().then((Practitioner: fhir.Practitioner) =>setpractitioner(Practitioner));
  }, [client]);

  return practitioner == null ? (
    <div>Loading...</div>   
  ) : (
    <div>
    <PractitionerContext.Provider value={practitioner}>{children}</PractitionerContext.Provider></div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const usePractitioner = (): fhir.Practitioner => useContext(PractitionerContext)!;