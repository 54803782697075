import React from 'react';
import {
  Grid,
  Divider,
  Box,
  Card,
  CardContent,
  ListItemIcon,
  List,
  Typography,
  ListItemText,
  Button,
} from '@mui/material';
import Guage from './guage';
const UMReview = () => {
  function generate(element: React.ReactElement) {
    return [0, 1, 2, 4, 5].map((value) =>
      React.cloneElement(element, {
        key: value,
      })
    );
  }
  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>
            Utilization Manager Review
          </p>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <td className="thead">Reviewer Name</td>
                <td className="thead">Criteria</td>
                <td className="thead">Recommendation</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="trow">Adam Davidson</td>
                <td className="trow">MDM</td>
                <td className="trow">99213</td>
              </tr>
            </tbody>
          </table>
          <p className="thead">Description</p>
          <p style={{ fontWeight: 400, color: '#303952' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.{' '}
          </p>
          <Divider />
          <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>LOS ANALYSIS</p>
          {/* <img src='static/images/date.PNG' width="100%"/> */}
          <img src="static/images/LOS-NEW.gif" width="100%" alt="s" />
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex' }}>
                <p style={{ fontSize: '22px', fontWeight: 700 }}>
                  <span style={{ color: '#A8C3FF' }}>■</span>{' '}
                  <span style={{ color: '#3E4F7C' }}>0.5</span>
                </p>
                &nbsp;
                <ListItemText primary="days / 156 hrs" secondary="Actual LOS" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex' }}>
                <p style={{ fontSize: '22px', fontWeight: 700 }}>
                  <span style={{ color: '#66D884' }}>■</span>{' '}
                  <span style={{ color: '#3E4F7C' }}>0.5</span>
                </p>
                &nbsp;
                <ListItemText primary="days / 96 hrs" secondary="Geometric LOS" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex' }}>
                <p style={{ fontSize: '22px', fontWeight: 700 }}>
                  <span style={{ color: '#C08269' }}>■</span>{' '}
                  <span style={{ color: '#3E4F7C' }}>0.5</span>
                </p>
                &nbsp;
                <ListItemText primary="days / 24 hrs" secondary="Avoidable days" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box sx={{ display: 'flex' }}>
                <p style={{ fontSize: '22px', fontWeight: 700 }}>
                  <span style={{ color: '#FFFFFF ' }}>■</span>{' '}
                  <span style={{ color: '#3E4F7C' }}>0.5</span>
                </p>
                &nbsp;
                <ListItemText secondary="Excess days" primary="days / 12 hrs" />
              </Box>
            </Grid>
          </Grid>
          {/* <img src='static/images/da.PNG' width="100%" alt='d'/> */}
        </Grid>
        <Grid item xs={12} sm={4}>
          <p style={{ fontSize: '14px', color: '#3E4F7C', fontWeight: 500 }}>AI Suggestions</p>
          <div style={{ textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ marginTop: '90px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: '#FA4900',
                    position: 'absolute',
                  }}
                >
                  OBS
                </span>
              </div>
              <div>
                <Guage />
              </div>
              <div style={{ marginTop: '90px', marginLeft: '-30px' }}>
                <span
                  style={{
                    fontSize: '14px',
                    fontWeight: 700,
                    color: ' #005DFF',
                    position: 'absolute',
                  }}
                >
                  IN
                </span>
              </div>
            </div>
            {/* <span style={{ fontSize: '14px', fontWeight: 700, color: '#FA4900' }}>OBS</span>
            &nbsp;&nbsp;
            <Guage/>
           
            &nbsp;&nbsp;
            <span style={{ fontSize: '14px', fontWeight: 700, color: ' #005DFF' }}>IN</span> */}
            <Divider />
          </div>
          <Box sx={{ p: 2 }}>
            <p
              style={{
                fontSize: '14px',
                padding: '0px !important',
                color: '#3E4F7C',
                fontWeight: 500,
              }}
            >
              Suggestion &nbsp;
              <span
                style={{
                  backgroundColor: '#F34036',
                  borderRadius: '4px',
                  color: 'white',
                  padding: '2px',
                }}
              >
                5
              </span>
            </p>

            <Card sx={{ minWidth: 275 }}>
              <CardContent>
                <Typography variant="caption" color="text.secondary">
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                  exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </Typography>
              </CardContent>
              {/* <CardActions> */}
              <Box sx={{ p: 1 }}>
                <Button startIcon={<i className="fa fa-question-circle" />} size="small">
                  Learn More
                </Button>
                <div style={{ float: 'right' }}>
                  <i className="fa fa-trash" aria-hidden="true" />
                  &nbsp;&nbsp;
                </div>
              </Box>

              {/* </CardActions> */}
            </Card>
          </Box>
          <Box sx={{ p: 2 }}>
            {generate(
              <Box sx={{ backgroundColor: 'white', mb: 1 }} className="ca">
                <p style={{ padding: '2px' }}>
                  {' '}
                  <span style={{ color: '#F34036' }}>●</span> Lorem ipsum dolor sit amet,
                  consectetur adipd.
                </p>
              </Box>
            )}
          </Box>
          <Box sx={{ p: 2 }}>
            <p
              style={{
                fontSize: '14px',
                padding: '0px !important',
                color: '#3E4F7C',
                fontWeight: 500,
              }}
            >
              UM Suggestions &nbsp;
              <span
                style={{
                  backgroundColor: '#F34036',
                  borderRadius: '4px',
                  color: 'white',
                  padding: '2px',
                }}
              >
                5
              </span>
            </p>

            {generate(
              <Box sx={{ backgroundColor: 'white', mb: 1 }} className="ca">
                <p style={{ padding: '2px' }}>
                  <span style={{ color: '#F34036' }}>●</span> Adam Davidson
                </p>
                <p style={{ marginLeft: '10px' }}>Patient: Thomas, Cameron</p>
                <p style={{ marginLeft: '10px' }}>Subject: CDI QUERY</p>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default UMReview;
