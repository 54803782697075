
import React from 'react';
import { firebas as firebase } from '../MedicalNecessary/firebase';
import { usePractitioner } from '../practitionerProvider';
import axios from 'axios';
const Everything = (props) => {
  const firestore = firebase.firestore();
  React.useEffect(() => {
    //props.le
    if (props && props.resource && props.resource.length !== 0) {
      // console.log(JSON.stringify(props.resource.resourceType) + "everything....");
       ///let a=JSON.stringify(props.resource)
       
       let aa=props.resource.map(res=>res.resourceType)
      let uniqueArray = aa.filter(function(item, pos) {
        return aa.indexOf(item) == pos;
    })
      console.log(uniqueArray)
      sendMessage(props.resource);
    }
    practitioner();
  }, [props]);
  React.useEffect(() => {
    practitioner();
  }, []);
  const pract = usePractitioner();
  const sendMessage = async (res) => {
    // var db = firestore;
    //axios.post('http://localhost:5001/smartonfhir-274312/us-central1/everything', {
    await axios
      .post('https://us-central1-smartonfhir-274312.cloudfunctions.net/everything', {
        id: props.patient.id,
        data: JSON.stringify(res),
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    // const dbRef = db.collection(`patient/${props.patient.id}/Archive`).doc(props.patient.id);
    // await dbRef.set({
    //   data: JSON.stringify(res),
    // });
  };
  const practitioner = async () => {
    await axios
      .post('https://us-central1-smartonfhir-274312.cloudfunctions.net/practitioner', {
        id: pract.id,
        pract: JSON.stringify(pract),
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    // var db = firestore;
    // const dbRefs = db.collection(`ProviderSpecific`).doc(pract.id);
    // // await dbRefs.set({
    // //   data: "",
    // // });
    // await dbRefs
    //   .collection('Provider')
    //   .doc(pract.id)
    //   .set({
    //     data: JSON.stringify(pract),
    //   });
    // await dbRefs.collection('AllAccounts').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('Locations').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('Downloads').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('CloudDrive').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('Tags').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('SmartGroups').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('Favorites').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('SharedwithMe').doc(pract.id).set({
    //   data: '',
    // });

    // await dbRefs.collection('Preference').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('Tasks').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('AllDrafts').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('Ratings').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('Apps').doc(pract.id).set({
    //   data: '',
    // });
    // await dbRefs.collection('CloudFunctions').doc('Create Version').set({
    //   data: '',
    // });
    // await dbRefs.collection('CloudFunctions').doc('Filter Duplicates').set({
    //   data: '',
    // });
    // await dbRefs.collection('CloudFunctions').doc('Create Provinence').set({
    //   data: '',
    // });
    // await dbRefs.collection('CloudFunctions').doc('Remove Obsolete Versions').set({
    //   data: '',
    // });
  };
  return <div></div>;
};
export default Everything;

// SmartRules (CloudFunctions)
